/**
 * A series of utility functions to help with common xFrame4 tasks.
 */

import { store } from '@redux/store';
import jsonTranslations from '@res/json/translations.json';
import { numberFormat } from './Functions';

/**
* Get the translation for a text.
* 
* @param key The key of the text to translate.
* @param language The translation language code (eg: en, hu, ro).
* @returns The translation for the language.
*/
export function tl(key: string, language: string): string
{
    let translations = jsonTranslations as any;

    if (translations[key] !== undefined && translations[key][language] !== undefined)
    {
        return translations[key][language];
    }
    else
    {
        return key;
    }
}

/**
 * Get the translation for the app language (set in Redux).
 * 
 * @param key The key of the text to translate.
 * @returns The translation for the app language.
 */
export function t(key: string): string
{
    return tl(key, store.getState().layout.language.code);
}

/**
 * Check if the language is RTL.
 * 
 * @param languageCode The 2-letter language code.
 * @returns True if the language is RTL.
 */
export function isRtl(languageCode: string)
{
    let rtlLanguageCodes = [
        'ar', // Arabic
        'ckb', // Sorani
        'dv', // Divehi
        'fa', // Persian
        'he', // Hebrew
        'ku', // Kurdish
        'ur', // Urdu
        'ps', // Pashto
        'sd', // Sindhi
        'ug', // Uyghur
        'yi', // Yiddish
    ];

    return rtlLanguageCodes.includes(languageCode);
}

/**
 * Format a price to a user-friendly format.
 * 
 * @param price The price to format.
 * @param showCurrency True to show the currency. For now the currency is hardcoded in the translations.json file.
 */
export function formatPrice(price: number, showCurrency = true): string
{
    let formattedPrice = numberFormat(price, 2, '.', ',');
    if (showCurrency) formattedPrice += ' ' + t('CURRENCY');

    return formattedPrice;
}