import { FunctionComponent, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import SegmentCondition from '@business/contacts/SegmentCondition';
import Contact from '@business/contacts/Contact';
import ContactGroup from '@business/contacts/ContactGroup';
import AddEditForm, { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import SegmentConditionEditorContainer from './SegmentConditionEditorContainer';

interface ExportContactsFormProps
{
    onClose: () => void;
    contactGroup?: ContactGroup;
}

const ExportContactsForm: FunctionComponent<ExportContactsFormProps> = (props) =>
{
    const t = useTranslate();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [segmentConditions, setSegmentConditions] = useState<SegmentCondition[]>([]);

    const onValidate = (): boolean =>
    {
        return true;
    }

    const onExport = async () =>
    {
        setIsProcessing(true);

        try
        {
            if (props.contactGroup)
            {
                // Export contacts from a specific contact group
                const { success, downloadLink } = await props.contactGroup.exportToExcel();
                if (success)
                {
                    window.open(downloadLink, '_blank');
                }
                return success;
            } 
            else
            {
                // Export contacts based on segment conditions
                const { success, downloadLink } = await Contact.exportContactsToExcel(segmentConditions);
                if (success)
                {
                    window.open(downloadLink, '_blank');
                }
                return success;
            }
        }
        catch (error)
        {
            if (error instanceof Error)
            {
                setErrorMessages([error.message]);
            }
            else
            {
                setErrorMessages([t('ERROR_UNKNOWN')]);
            }
        }

        setIsProcessing(false);

        return false;
    }

    /** Tab: general */
    let cmpTabGeneral = props.contactGroup ?
        // If we have a contact group, show a message instead of segment conditions
        <>
            <Row className="my-3">
                <Col>
                    <p>{`${t('EXPORT_CONTACTS_FROM_GROUP_MESSAGE')}: ${props.contactGroup.name}`}</p>
                </Col>
            </Row>
            {isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </> :
        // Otherwise show segment condition editor
        <>
            <SegmentConditionEditorContainer
                segmentConditions={segmentConditions}
                onChange={(segmentConditions) => setSegmentConditions(segmentConditions)}
            />
            {isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </>;

    /** Render */
    return (
        <>
            <AddEditForm
                mode={AddEditFormMode.Add}
                title={props.contactGroup ? `${t('EXPORT_CONTACTS_FROM_GROUP')} ${props.contactGroup.name}` : t('EXPORT_CONTACTS')}
                saveButtonLabel={t('EXPORT')}
                errorMessages={errorMessages}
                onValidate={onValidate}
                onSave={() => onExport()}
                onClose={props.onClose}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </AddEditForm>
        </>
    );
}

export default ExportContactsForm;