import { FunctionComponent, useState } from 'react';
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import AutomatedJourneyMessage from '@business/mailing/AutomatedJourneyMessage';
import EmailCampaign from '@business/mailing/EmailCampaign';
import { BulkMessageStats } from '@business/mailing/BulkMessageStatsManager';
import EmailMessage from '@business/mailing/EmailMessage';
import StatDetailsContainer from '@components/stats/StatDetailsContainer';
import StatsDetailsRow from '@components/stats/StatDetailsRow';
import EmailMessageStatsDashboard from '@components/stats/EmailMessageStatsDashboard';
import TabContainer from '@components/_admin/entity/TabContainer';
import PageTitle from '@components/_admin/hyper/components/PageTitle';

interface BulkMessageStatFormProps
{
    title?: string;
    emailCampaign?: EmailCampaign;
    automatedJourneyMessage?: AutomatedJourneyMessage
    onClose: () => void;
}

const BulkMessageStatsForm: FunctionComponent<BulkMessageStatFormProps> = (props) =>
{
    const t = useTranslate();
    const [bulkMessageStats, setBulkMessageStats] = useState<BulkMessageStats>();
    const [emailMessages, setEmailMessages] = useState<EmailMessage[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /** Load stats */
    useLoad(async () =>
    {
        await loadStats();
    });

    /**
     * Load stats for the given bulk message.
     */
    const loadStats = async () =>
    {
        setIsLoading(true);

        // load the stats for the given bulk message, for each email message belonging to the bulk message
        let stats: BulkMessageStats | null = null;
        if (props.emailCampaign)
        {
            stats = await props.emailCampaign.getStats();
        }
        else if (props.automatedJourneyMessage)
        {
            stats = await props.automatedJourneyMessage.getStats();
        }

        if (stats) 
        {
            // load the email messages too
            let emailMessageResult = await EmailMessage.manager.load({ id_In: stats?.map(s => s.emailMessageId) });
            if (emailMessageResult) 
            {
                setEmailMessages(emailMessageResult.entities);
                setBulkMessageStats(stats);
            }
        }

        setIsLoading(false);
    }

    /**
     * Find the email message entity for the given id.
     */
    const getEmailMessageForId = (emailMessageId: number) =>
    {
        return emailMessages.find(em => em.id === emailMessageId)!;
    }

    /** Email open stats for each contact */
    let cmpContactEmailOpenStats =
        <>
            {bulkMessageStats &&
                <StatDetailsContainer
                    statDetails={bulkMessageStats?.map(emailMessageStat =>
                    {
                        return {
                            emailMessage: getEmailMessageForId(emailMessageStat.emailMessageId),
                            statDetailsRows: emailMessageStat.stats.contactEmailOpenStats!.map(contactEmailOpenStat =>
                            {
                                return [
                                    contactEmailOpenStat.contactEmail,
                                    contactEmailOpenStat.emailOpenCount
                                ]
                            })
                        }
                    })}
                />
            }
        </>

    /** Email click stats for each contact */
    let cmpContactEmailClickStats =
        <>
            {bulkMessageStats &&
                <StatDetailsContainer
                    statDetails={bulkMessageStats?.map(stat =>
                    {
                        return {
                            emailMessage: getEmailMessageForId(stat.emailMessageId),
                            statDetailsRows: stat.stats.contactEmailClickStats!.map(contactEmailClickStat =>
                            {
                                return [
                                    contactEmailClickStat.contactEmail,
                                    contactEmailClickStat.emailClickCount
                                ]
                            }
                            )
                        }
                    })}
                />
            }
        </>

    /** Url click count stats */
    let cmpUrlClickCountStats =
        <>
            {bulkMessageStats &&
                <StatDetailsContainer
                    statDetails={bulkMessageStats!.map(stat =>
                    {
                        return {
                            emailMessage: getEmailMessageForId(stat.emailMessageId),
                            statDetailsRows: stat.stats.urlClickCountStats!.map(urlClickCountStat =>
                            {
                                return [
                                    urlClickCountStat.url,
                                    urlClickCountStat.clickCount
                                ]
                            }
                            )
                        }
                    })}
                />
            }
        </>

    /** The list of contacts who clicked on a url. A list of contacts is displayed for each url. */
    let cmpUrlClickContactStats =
        <>
            {bulkMessageStats && bulkMessageStats.length === 1 &&
                <Container>
                    {
                        bulkMessageStats[0].stats.urlClickContactStats!.map((urlClickContactStat, index) =>
                            <div className="mb-3">
                                <div className="mb-1 fw-bold">{urlClickContactStat.url}</div>
                                <div>
                                    {
                                        urlClickContactStat.contactStats.map((contactStat, index) =>
                                            <StatsDetailsRow
                                                key={index}
                                                statDetailsRowData={[
                                                    contactStat.contactEmail,
                                                    contactStat.emailClickCount
                                                ]}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </Container>
            }

            {bulkMessageStats && bulkMessageStats.length > 1 &&
                <TabContainer
                    tabs={bulkMessageStats!.map(stat =>
                    {
                        return {
                            eventKey: stat.emailMessageId as unknown as string,
                            title: stat.emailMessageId + ' - ' + getEmailMessageForId(stat.emailMessageId).subject,
                            content: <Container>
                                {
                                    stat.stats.urlClickContactStats!.map((urlClickContactStat, index) =>
                                        <div className="mb-3">
                                            <div className="mb-1 fw-bold">{urlClickContactStat.url}</div>
                                            <div>
                                                {
                                                    urlClickContactStat.contactStats.map((contactStat, index) =>
                                                        <StatsDetailsRow
                                                            key={index}
                                                            statDetailsRowData={[
                                                                contactStat.contactEmail,
                                                                contactStat.emailClickCount
                                                            ]}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </Container>
                        };
                    })}
                />
            }
        </>

    /** Contact unsubscribe stats */
    let cmpContactUnsubscribeStats =
        <>
            {bulkMessageStats &&
                <StatDetailsContainer
                    statDetails={bulkMessageStats!.map(stat =>
                    {
                        return {
                            emailMessage: getEmailMessageForId(stat.emailMessageId),
                            statDetailsRows: stat.stats.contactUnsubscribeStats!.map(contactUnsubscribeStat =>
                            {
                                return [
                                    contactUnsubscribeStat.contactEmail,
                                    contactUnsubscribeStat.unsubscribeCount
                                ]
                            }
                            )
                        }
                    })}
                />
            }
        </>

    let cmpLoader =
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
        </div>

    /** Render */
    return (
        <>
            {!bulkMessageStats && cmpLoader}

            {bulkMessageStats &&
                <div className="mb-3">
                    <Row className="align-items-center">
                        <Col lg={4}>
                            <PageTitle title={props.title ?? t('BULK_MESSAGE_STATS')} />
                        </Col>
                        <Col lg={8}>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="me-2"
                                    disabled={isLoading}
                                    onClick={() => loadStats()}
                                >
                                    <i className="mdi mdi-autorenew"></i>
                                </Button>

                                <Button
                                    variant="secondary"
                                    disabled={isLoading}
                                    onClick={() => props.onClose()}
                                >
                                    <i className="mdi mdi-close"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    {isLoading && cmpLoader}

                    {!isLoading &&
                        <>
                            <div className="mb-4">
                                {/* Widgets for the single email message (no tab) */}
                                {
                                    bulkMessageStats && bulkMessageStats.length === 1 &&
                                    <EmailMessageStatsDashboard
                                        emailMessage={getEmailMessageForId(bulkMessageStats[0].emailMessageId)}
                                        emailMessageStat={bulkMessageStats[0]}
                                    />
                                }
                                {/* Widgets for multiple email messages (in tabs for each split test) */}
                                {
                                    bulkMessageStats && bulkMessageStats.length > 1 &&
                                    <TabContainer
                                        tabs={bulkMessageStats!.map(stat =>
                                        {
                                            return {
                                                eventKey: stat.emailMessageId as unknown as string,
                                                title: stat.emailMessageId + ' - ' + getEmailMessageForId(stat.emailMessageId).subject,
                                                content: <EmailMessageStatsDashboard
                                                    emailMessage={getEmailMessageForId(stat.emailMessageId)}
                                                    emailMessageStat={stat}
                                                />
                                            };
                                        })}
                                    />
                                }
                            </div>


                            {/* Detailed stats for each email message */}
                            <div className="mb-4">
                                <Card>
                                    <Card.Body>
                                        <TabContainer
                                            tabs={[
                                                {
                                                    eventKey: 'contactEmailOpenStats,',
                                                    title: t('BULK_MESSAGE_STAT_CONTACT_EMAIL_OPENS'),
                                                    content: cmpContactEmailOpenStats,
                                                },
                                                {
                                                    eventKey: 'contactEmailClickStats',
                                                    title: t('BULK_MESSAGE_STAT_CONTACT_EMAIL_CLICKS'),
                                                    content: cmpContactEmailClickStats,
                                                },
                                                {
                                                    eventKey: 'urlClickCountStats',
                                                    title: t('BULK_MESSAGE_STAT_URL_CLICK_COUNTS'),
                                                    content: cmpUrlClickCountStats,
                                                },
                                                {
                                                    eventKey: 'urlClickContactStats',
                                                    title: t('BULK_MESSAGE_STAT_URL_CLICK_FOR_CONTACTS'),
                                                    content: cmpUrlClickContactStats,
                                                },
                                                {
                                                    eventKey: 'contactUnsubscribeStats',
                                                    title: t('BULK_MESSAGE_STAT_CONTACT_UNSUBSCRIBES'),
                                                    content: cmpContactUnsubscribeStats,
                                                }
                                            ]}
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
}

export default BulkMessageStatsForm;