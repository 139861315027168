import { gql } from '@apollo/client';
import ContactGroupGenerated, { ContactGroupGeneratedEntityManager } from './generated/ContactGroup.generated';
import client from '@graphql/apollo-client';

export class ContactGroupEntityManager extends ContactGroupGeneratedEntityManager<ContactGroup>
{
    constructor()
    {
        super({
            createEntity: () => new ContactGroup(),
        });
    }
}

export class ContactGroup extends ContactGroupGenerated
{
    static manager: ContactGroupEntityManager = new ContactGroupEntityManager();

    /**
     * Load light contact groups entities to display on the unsubscribe page.
     */
    static async loadLightForUnsubscribe(contactId: number, unsubscribeToken: string)
    {
        let query = `
            query ContactGroupsForUnsubscribe($contactId: Int!, $unsubscribeToken: String!) {
                contactGroupsForUnsubscribe(contactId: $contactId, unsubscribeToken: $unsubscribeToken) {
                    id
                    name
                }
            }
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                contactId: contactId,
                unsubscribeToken: unsubscribeToken,
            },
        });

        let contactGroupsLight: ContactGroupLight[] = [];

        if (result.data.contactGroupsForUnsubscribe && result.data.contactGroupsForUnsubscribe.length > 0)
        {
            for (let gqlContactGroup of result.data.contactGroupsForUnsubscribe)
            {
                let contactGroupLight: ContactGroupLight = {
                    id: gqlContactGroup.id,
                    name: gqlContactGroup.name,
                };
                contactGroupsLight.push(contactGroupLight);
            }
        }

        return contactGroupsLight;
    }

    /**
     * Export all contacts belonging to this contact group to an Excel file.
     * 
     * @returns An object containing success status and download link.
     */
    async exportToExcel()
    {
        let mutation = `
        mutation ExportContactGroupToExcel($contactGroupId: Int!) {
            exportContactGroupToExcel(contactGroupId: $contactGroupId) {
                success
                downloadLink
            }
        }
        `;
        
        let result = await client.mutate({
            mutation: gql(mutation),
            variables: {
                contactGroupId: this.id,
            },
        });

        return { 
            success: result.data.exportContactGroupToExcel.success as boolean, 
            downloadLink: result.data.exportContactGroupToExcel.downloadLink as string 
        };
    }
}

export type ContactGroupLight = {
    id: number,
    name: string,
}

export default ContactGroup;
