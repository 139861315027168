import { FunctionComponent } from 'react';
import React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import User from '@xFrame4/business/users/User';
import { formatDateTime } from '@xFrame4/common/Functions';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import UserAddEditForm from './UserAddEditForm';
import EntityView from '../EntityView';
import { AddEditFormMode } from '../AddEditForm';

interface UserViewProps
{

}

const UserView: FunctionComponent<UserViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<User>(User);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<User>>(
        () => [
            { field: 'id', headerName: t('ID'), width: 100 },
            { field: 'dateJoined', headerName: t('DATE_JOINED'), valueFormatter: (params) => formatDateTime(params.value), flex: 1 },
            { field: 'email', headerName: t('EMAIL'), type: 'string', flex: 1 },
            { field: 'firstName', headerName: t('FIRST_NAME'), type: 'string', flex: 1 },
            { field: 'lastName', headerName: t('LAST_NAME'), type: 'string', flex: 1 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'nickname', headerName: t('NICKNAME'), type: 'string', flex: 1 },
            { field: 'verified', headerName: t('VERIFIED'), type: 'boolean', flex: 1 },
            { field: 'isActive', headerName: t('IS_ACTIVE'), type: 'boolean', flex: 1 },
            { field: 'isStaff', headerName: t('IS_STAFF'), type: 'boolean', flex: 1 },
        ],
        [],
    );


    /** Render */
    return (
        <>
            {addEditEntity &&
            <UserAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<User>
                id="users"
                title={t('USERS')}
                manager={User.manager}
                baseFilter={{}}
                pageSize={50}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'email_Icontains', label: t('EMAIL'), type: FieldEditorType.Text},
                    {field: 'firstName_Icontains', label: t('FIRST_NAME'), type: FieldEditorType.Text},
                    {field: 'lastName_Icontains', label: t('LAST_NAME'), type: FieldEditorType.Text},
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                    {field: 'nickname_Icontains', label: t('NICKNAME'), type: FieldEditorType.Text},
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.email + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default UserView;