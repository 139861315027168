import { FunctionComponent, useState } from 'react';
import ContactGroup from '@business/contacts/ContactGroup';
import { useLoad } from '@xFrame4/components/Hooks';
import CheckboxList from '@xFrame4/components/common/CheckboxList';

interface ContactGroupsEditorProps
{
    selectedContactGroups: ContactGroup[];
    onChange: (contactGroups: ContactGroup[]) => void;
}

/**
 * Component: Editor for contact group selection
 */
const ContactGroupsEditor: FunctionComponent<ContactGroupsEditorProps> = (props) =>
{
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

    useLoad(async () => {
        // load the contact groups
        const contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);
    });
    
    /** Render */
    return (
        <>
            <CheckboxList
                dataSource={contactGroups}
                dataSourceDisplayMember="name"
                dataSourceValueMember="id"
                controlId="contact-groups"
                value={props.selectedContactGroups}
                onChange={(selectedItems) => props.onChange(selectedItems as ContactGroup[])}
            />
        </>
    );
}

export default ContactGroupsEditor;