import { FunctionComponent } from 'react';

export interface CardProps
{
    children?: React.ReactNode;
    className?: string;
}

/**
 * Component: Hyper theme card.
 */
const Card: FunctionComponent<CardProps> = (props) =>
{
    /** Render */
    return (
        <div className={`card ${props.className}`}>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    );
}

export default Card;