import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import ContactHistory from '@business/contacts/ContactHistory';
import Contact from '@business/contacts/Contact';
import CustomField, { CustomFieldValue } from '@business/fields/CustomField';
import CustomFieldsEditor from './CustomFieldsEditor';
import { CustomFieldAvailableFor } from '@business/fields/generated/CustomField.generated';

interface ContactHistoryAddEditFormProps extends EntityAddEditFormProps<ContactHistory>
{
    contact: Contact;
}

const ContactHistoryAddEditForm: FunctionComponent<ContactHistoryAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<ContactHistory>(props.entity ?? new ContactHistory());
    const [customFieldValues, setCustomFieldValues] = useState<CustomFieldValue[]>([]);

    /** Load additional data */
    useLoad(async () => {
        // load custom fields and values
        let customFieldResult = await CustomField.manager.load({ availableFor_In: [CustomFieldAvailableFor.ALL, CustomFieldAvailableFor.CONTACT_HISTORY] });
        if (customFieldResult != null)
        {
            let cfvs: CustomFieldValue[] = await entity.customFieldValueManager.getValuesForCustomFields(customFieldResult.entities, mode == AddEditFormMode.Edit);
            setCustomFieldValues(cfvs);
        }
    });

    /** Save */
    const onSave = async () => {
        // set contact
        entity.contact = props.contact;

        // save entity
        let result = await entity.save();

        // save custom field values
        if (result)
        {
            // save custom field values
            for (let cfv of customFieldValues)
            {
                await entity.customFieldValueManager.setCustomFieldValue(cfv.customField, cfv.value);
            }
        }

        return result;
    };

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="eventName"
                        label={t('EVENT_NAME')}
                        isRequired={true}
                        value={entity?.eventName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.DateTimeLocal}
                        field="eventDate"
                        label={t('EVENT_DATE')}
                        isRequired={true}
                        value={entity?.eventDate}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: custom fields */
    let cmpTabCustomFields =
        <CustomFieldsEditor 
            customFieldValues={customFieldValues}
            onCustomFieldValuesChanged={(customFieldValues) => setCustomFieldValues(customFieldValues)}
        />

    /** Render */
    return (
        <>
            <EntityAddEditForm<ContactHistory>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('CONTACT_HISTORY') + ' >>> ' + props.contact.email}
                titleEdit={t('EDIT') + ': ' + props.contact.email + ' >>> ' + entity?.eventName}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'customFields',
                            title: t('ADD_EDIT_TAB_CUSTOM_FIELDS'),
                            content: cmpTabCustomFields,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default ContactHistoryAddEditForm;