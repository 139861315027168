import EmailTemplateGenerated, { EmailTemplateGeneratedEntityManager } from './generated/EmailTemplate.generated';

export class EmailTemplateEntityManager extends EmailTemplateGeneratedEntityManager<EmailTemplate>
{
    constructor()
    {
        super({
            createEntity: () => new EmailTemplate(),
        });
    }
}

export class EmailTemplate extends EmailTemplateGenerated
{
    static manager: EmailTemplateEntityManager = new EmailTemplateEntityManager();
}

export enum EmailTemplateBaseTemplateFile
{
    EmailHotel = 'email-hotel.html'
}

export enum EmailTemplateBodyTemplateFile
{
    CustomDefault = 'custom-default.html',
}

export enum EmailTemplateLanguage
{
    EN = 'en',
    HU = 'hu',
    DE = 'de',
    RO = 'ro',
}

export default EmailTemplate;