import { FunctionComponent } from 'react';

export interface StatusBadgeProps {
    /**
     * The text to display in the badge
     */
    text: string;
    
    /**
     * Mapping of status values to colors
     * If not provided for a specific status, will use defaultColor
     */
    colorMapping?: Record<string, string>;
    
    /**
     * The status value to use for color mapping
     */
    status: string;
    
    /**
     * Default color to use if no mapping is provided for the status
     * Default: 'info'
     */
    defaultColor?: string;
    
    /**
     * Optional CSS class name to add to the badge
     */
    className?: string;
    
    /**
     * Optional inline styles to apply to the badge
     */
    style?: React.CSSProperties;
}

/**
 * Component: A reusable status badge that displays a status with appropriate coloring.
 * 
 * Usage:
 * <StatusBadge 
 *   text={t('ORDER_STATUS_' + order.status)}
 *   status={order.status} 
 *   colorMapping={{ CREATED: 'info', FULFILLED: 'success', CANCELLED: 'danger' }}
 * />
 * 
 * <StatusBadge 
 *   text={t(review.status)}
 *   status={review.status} 
 *   colorMapping={{ APPROVED: 'success', REJECTED: 'danger', PENDING: 'warning' }}
 * />
 */
const StatusBadge: FunctionComponent<StatusBadgeProps> = (props) => {
    const { 
        text,
        status, 
        colorMapping = {}, 
        defaultColor = 'info',
        className = '',
        style = {}
    } = props;
    
    // Determine the color based on the provided mapping or use the default color
    const color = colorMapping[status] || defaultColor;
    
    const defaultStyle = { fontSize: '0.75rem', ...style };
    
    return (
        <div 
            className={`badge badge-${color}-lighten ${className}`}
            style={defaultStyle}
        >
            {text}
        </div>
    );
};

export default StatusBadge; 