import { forwardRef, useImperativeHandle, useRef } from 'react';
import { checkValidityOfFieldEditorInputs, findFieldEditorInputs, resetFieldEditorInputs } from './FieldEditor';

interface XFormProps
{
    /** Enable submit on Enter */
    enableSubmitOnEnter?: boolean;
    /** Class name for the form. */
    className?: string;
    /** Child JSX element or elements. */
    children?: React.ReactNode;
    /** Called when the form is checking the validity of the FieldEditor components. */
    onCheckingValidity?: () => void;
    /** Custom validation. Validates the data before submitting but only after the FieldEditor components are validated. */
    onValidate?: () => boolean;
    /** Handle the submit process. */
    onSubmit?: () => Promise<void | boolean>;
}

/**
 * Component: basic form for handling form submit.
 * Use forwardRef and useImperativeHandle to expose the submit method.
 * https://legacy.reactjs.org/docs/forwarding-refs.html
 * https://react.dev/reference/react/useImperativeHandle
 */
const XForm = forwardRef((props: XFormProps, ref) =>
{
    const formDivRef = useRef<HTMLDivElement>(null);

    /** Check the validity of the FieldEditor components. */
    const checkValidity = () =>
    {
        if (formDivRef.current == null) return false;

        if (props.onCheckingValidity) props.onCheckingValidity();
        
        let isValid = true;

        let fieldEditorInputs = findFieldEditorInputs(formDivRef.current);
        isValid = checkValidityOfFieldEditorInputs(fieldEditorInputs);

        return isValid;
    }

    /** Validate the form. */
    const validate = () =>
    {
        let isValid = true;

        // Check the validity of the FieldEditor components
        isValid = checkValidity();

        // Additional custom validation
        if (isValid)
        {
            if (props.onValidate !== undefined) isValid = props.onValidate();
        }


        return isValid;
    };

    /** Handle form submit */
    const submit = async () =>
    {
        let isValid = validate();

        if (isValid)
        {
            if (props.onSubmit !== undefined) await props.onSubmit();
        }
    };

    /** Reset the form */
    const reset = () =>
    {
        if (formDivRef.current == null) return;

        resetFieldEditorInputs(findFieldEditorInputs(formDivRef.current));
    };

    useImperativeHandle(ref, () => ({
        validate: validate,
        submit: submit,
        reset: reset
    }));

    /** Handle the enter key */
    const handleKeyDown = (event: React.KeyboardEvent) => 
    {
        if (event.key === 'Enter' && !event.shiftKey) 
        {
            event.preventDefault();
            submit();
        }
    };
    
    /** Render */
    return (
        <div
            ref={formDivRef}
            onKeyDown={(event) => handleKeyDown(event)}
            className={'x-form ' + (props.className ?? '')}
        >
            {props.children}
        </div>
    );
});

export default XForm;