import React from 'react';
import { FunctionComponent, useRef, useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { useTranslate, useSetStateFromProp } from '@xFrame4/components/Hooks';
import WizardStep, { WizardStepType } from './WizardStep';
import TabContainer from './TabContainer';
import PageTitle from '@components/_admin/hyper/components/PageTitle';

interface WizardProps
{
    title: string;
    steps: WizardStepType[];
    /** Is the wizard processing something? */
    isProcessing?: boolean;
    /** An error message to display. */
    errorMessage?: string | null;
    /** Called when the step changes. */
    onStepChanged?: (previousStep: number, currentStep: number) => void;
    /** Validates the data before finishing. */
    onValidate?: () => boolean;
    /** Handle the finish. */
    onFinish?: () => Promise<boolean>;
    /** Called after the finish has been succesfuly handled. */
    afterFinish?: () => void;
    /** Closing the wizard (finished or not) */
    onClose?: () => void;
}

/**
 * Component: a wizard to create data/entitie/etc...
 * The user can navigate between the steps (tabs) of the wizzard.
 */
const Wizard: FunctionComponent<WizardProps> = (props) =>
{
    const t = useTranslate();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const stepRefs = useRef<(React.RefObject<{ validate: () => boolean }>)[]>(props.steps.map(() => React.createRef()));
    const [isProcessing, setIsProcessing] = useState<boolean>(props.isProcessing ?? false);
    useSetStateFromProp(setIsProcessing, props.isProcessing ?? false);
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage ?? null);
    useSetStateFromProp(setErrorMessage, props.errorMessage ?? null);

    /** Change the current step. */
    const changeStep = (newStep: number) =>
    {
        // validate new step
        if (newStep < 1 || newStep > props.steps.length) return;

        // call on step change
        if (props.onStepChanged) props.onStepChanged(currentStep, newStep);

        // set the new step
        setCurrentStep(newStep);
    }

    /**
     * Go to the previous step.
     */
    const prev = () =>
    {
        if (currentStep > 1) 
        {
            changeStep(currentStep - 1);
        }
    }

    /**
     * Go to the next step.
     */
    const next = async () =>
    {
        // Validate the current step
        const currentStepRef = stepRefs.current[currentStep - 1];
        const isValid = currentStepRef.current?.validate() ?? false;
        if (!isValid) return; // Validation failed for the current step
        
        // Validate the overall wizard
        if (props.onValidate && !props.onValidate()) 
        {
            return; // Overall validation failed
        }
    
        // If not last step, just navigate to the next step
        if (currentStep < props.steps.length) 
        {
            changeStep(currentStep + 1);
            return;
        }
    
        // If last step, handle the finish
        if (props.onFinish) 
        {
            let success = false;

            setIsProcessing(true);
            try
            {
                success = await props.onFinish();
                setIsProcessing(false);

                if (success) 
                {
                    if (props.afterFinish) props.afterFinish();
                    close();
                }
            }
            catch(error)
            {
                console.log(error);

                if (error instanceof Error) 
                {
                    setErrorMessage(error.message);
                } 
                else if (typeof error === 'string') 
                {
                    setErrorMessage(error);
                }
                else
                {
                    setErrorMessage(t('ERROR'));
                }
                
                setIsProcessing(false);
            }
        }
    }

    /** Close the wizard. */
    const close = () =>
    {
        props.onClose?.();
    };

    /**
     * When the user manually changes the tab.
     */
    const onManualTabChange = (key: string): boolean => 
    {
        // cancel tab change if is something processing
        if (isProcessing) return false;
        
        // validate current step
        const currentStepRef = stepRefs.current[currentStep - 1];
        const isValid = currentStepRef.current?.validate() ?? false;

        // if valid, change the step
        if (isValid)
        {
            changeStep(parseInt(key.split('-')[1]));
        }

        return isValid;
    }

    /** Tabs */
    const tabs = props.steps.map((step, index) => ({
        eventKey: `step-${index + 1}`,
        title: step.title,
        content: <WizardStep ref={stepRefs.current[index]} step={step} />,
    }));
    
    /** Render */
    return (
        <div className="xframe-wizzard">
            <PageTitle title={props.title} />

            <Card>
                <Card.Body>
                    <TabContainer 
                        tabs={tabs} 
                        activeKey={`step-${currentStep}`} 
                        onTabChange={(key) => onManualTabChange(key)}
                    />

                    {errorMessage != null && 
                    <div className="add-edit-error-message mt-3">
                        <div role="alert" className="fade text-danger alert alert-danger show">{errorMessage}</div>
                    </div>
                    }

                    <div className="row mt-3">
                        <div className="col-4">
                            <div className="d-flex justify-content-start">
                                <Button
                                    variant="secondary"
                                    onClick={() => close()}
                                    disabled={isProcessing}
                                >
                                    {t('CANCEL')}
                                </Button>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="d-flex justify-content-end">
                                <Button 
                                    variant="info" 
                                    onClick={() => prev()} 
                                    disabled={isProcessing}
                                    className="me-3"
                                    style={{ display: currentStep === 1 ? 'none' : 'block' }}
                                >
                                    {t('WIZARD_PREVIOUS')}
                                </Button>

                                <Button 
                                    variant="success" 
                                    onClick={() => next()}
                                    disabled={isProcessing}
                                >
                                    {currentStep === props.steps.length ? t('WIZARD_FINISH') : t('WIZARD_NEXT')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Wizard;