import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import AutomatedJourneyMessage from '@business/mailing/AutomatedJourneyMessage';
import AutomatedJourneyMessageAddEditForm from './AutomatedJourneyMessageAddEditForm';
import AutomatedJourney from '@business/mailing/AutomatedJourney';
import BulkMessageStatsForm from './BulkMessageStatsForm';

interface AutomatedJourneyMessageViewProps
{
    automatedJourney: AutomatedJourney;
}

const AutomatedJourneyMessageView: FunctionComponent<AutomatedJourneyMessageViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<AutomatedJourneyMessage>(AutomatedJourneyMessage);
    const [bulkMessageStatsEntity, setBulkMessageStatsEntity] = useState<AutomatedJourneyMessage>();

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<AutomatedJourneyMessage>>(
        () => [
            { field: 'orderNo', headerName: t('ORDER_NO'), type: 'number', width: 80 },
            { field: 'delay', headerName: t('DELAY'), type: 'number', width: 150 },
            { field: 'delayUnit', headerName: t('DELAY_UNIT'), type: 'string', width: 150, valueGetter: (params) => t(params.row.delayUnit) },
            { field: 'sendAfterEvent', headerName: t('SEND_AFTER_EVENT'), type: 'string', width: 150, valueGetter: (params) => t(params.row.sendAfterEvent ?? '') },
            { field: 'subject', headerName: t('SUBJECT'), type: 'string', flex: 1, valueGetter: (params) => params.row.emailMessage?.subject },
            { field: 'status', headerName: t('STATUS'), type: 'string', valueGetter: (params) => t(params.row.status) },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <AutomatedJourneyMessageAddEditForm 
                entity={addEditEntity}
                automatedJourney={props.automatedJourney}
                afterEdit={onAddEditClose}
            />
            }

            {bulkMessageStatsEntity &&
            <BulkMessageStatsForm 
                title={t('BULK_MESSAGE_STATS') + ' - ' + bulkMessageStatsEntity.orderNo + '. ' + bulkMessageStatsEntity.emailMessage.subject}
                automatedJourneyMessage={bulkMessageStatsEntity}
                onClose={() => setBulkMessageStatsEntity(undefined)}
            />
            }

            <EntityView<AutomatedJourneyMessage>
                id="automatedJourneyMessages"
                isVisible={bulkMessageStatsEntity === undefined}
                title={t('AUTOMATED_JOURNEY_MESSAGES')}
                manager={AutomatedJourneyMessage.manager}
                baseFilter={{ automatedJourney_Id: props.automatedJourney.id ?? -1 }}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'orderNo', label: t('ORDER_NO'), type: FieldEditorType.Number},
                    {field: 'subject_Icontains', label: t('SUBJECT'), type: FieldEditorType.Text},
                ]}
                actions={[
                    { label: t('STATS'), icon: <QueryStatsIcon />, onClick: (entity) => setBulkMessageStatsEntity(entity) }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.orderNo + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default AutomatedJourneyMessageView;