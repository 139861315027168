import { FunctionComponent, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { ColumnNamesAndFieldNamesForMapping } from '@business/contacts/Contact';
import ImportMapper from './ImportMapper';
import AddEditForm, { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import ContactHistory from '@business/contacts/ContactHistory';

interface ImportContactHistoryFormProps
{
    onClose: () => void;
}

const ImportContactHistoryForm: FunctionComponent<ImportContactHistoryFormProps> = (props) =>
{
    const t = useTranslate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [columnNamesAndFieldsNamesForMapping, setColumnNamesAndFieldsNamesForMapping] = useState<ColumnNamesAndFieldNamesForMapping | null>(null);
    const [columnNameToFieldNameMap, setColumnNameToFieldNameMap] = useState<{ [key: string]: string }> ({});
    
    /**
     * Initialize import.
     * @param p_file
     */
    const initImport = async (p_file: File) =>
    {
        setIsProcessing(true);

        // get column names and field names for mapping
        let cnfn = await ContactHistory.getColumnNamesAndFieldNamesForMapping(p_file);
        console.log(cnfn);
        setColumnNamesAndFieldsNamesForMapping(cnfn);

        setIsProcessing(false);
    }

    /**
     * Validate form.
     */
    const onValidate = () : boolean =>
    {
        // check if the email field is mapped
        let isEmailMapped = false;
        for (let columnName in columnNameToFieldNameMap)
        {
            if (columnNameToFieldNameMap[columnName] === 'email')
            {
                isEmailMapped = true;
                break;
            }
        }

        if (!isEmailMapped)
        {
            setErrorMessage(t('EMAIL_FIELD_NOT_MAPPED'));
        }

        return isEmailMapped;
    }

    /**
     * Import contacts from Excel file.
     */
    const onImport = async () : Promise<boolean> =>
    {
        setIsProcessing(true);

        let success = await ContactHistory.importContactsFromExcel(file!, columnNameToFieldNameMap);

        setIsProcessing(false);

        return success;
    }

    /** Tab: General */
    let cmpTabGeneral =
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="file"
                        label={t('FILE')}
                        onValueChanged={(field, file) => {
                            setFile(file);
                            initImport(file);
                        }}
                    />
                </Col>
            </Row>
            { columnNamesAndFieldsNamesForMapping &&
            <Row>
                <Col>
                    <ImportMapper 
                        columnNames={columnNamesAndFieldsNamesForMapping.columnNames}
                        fieldNames={[...columnNamesAndFieldsNamesForMapping.contactHistoryFieldNames!, ...columnNamesAndFieldsNamesForMapping.customFieldNames]}
                        onMappingChanged={(columnNameToFieldNameMap) => setColumnNameToFieldNameMap(columnNameToFieldNameMap)}
                    />
                </Col>
            </Row>
            }
            { isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </>
        
    /** Render */
    return (
        <>
            <AddEditForm 
                mode={AddEditFormMode.Add}
                title={t('IMPORT_CONTACT_HISTORY')}
                saveButtonLabel={t('IMPORT')}
                errorMessage={errorMessage}
                onValidate={() => onValidate()}
                onSave={() => onImport()}
                onClose={() => props.onClose()}
            >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
            </AddEditForm>
        </>
    );
}

export default ImportContactHistoryForm;