import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { numberFormat } from '@xFrame4/common/Functions';
import { t } from '@xFrame4/common/Utils';
import EmailMessage from '@business/mailing/EmailMessage';
import { EmailMessageStat, MessageStatsCalculator } from '@business/mailing/BulkMessageStatsManager';
import SimpleStatWidget from './SimpleStatWidget';

interface EmailMessageStatsDashboardProps
{
    emailMessage: EmailMessage;
    emailMessageStat: EmailMessageStat;
}

/**
 * Component: holds a list of widgets for displaying email message statistics.
 */
const EmailMessageStatsDashboard: FunctionComponent<EmailMessageStatsDashboardProps> = (props) =>
{
    let messageStatsCalculator = new MessageStatsCalculator(props.emailMessageStat.stats);

    const formatStatNumber = (value: number) => numberFormat(value, 0, '.', ',');
    const formatStatPercentage = (value: number) => numberFormat(value, 2, '.', ',') + '%';

    let recipientCount = formatStatNumber(messageStatsCalculator.stats.recipientCount);
    let pendingCount = formatStatNumber(messageStatsCalculator.stats.pendingCount);
    let deliveredCount = formatStatNumber(messageStatsCalculator.stats.deliveredCount);
    let failedCount = formatStatNumber(messageStatsCalculator.stats.failedCount);
    let bouncedCount = formatStatNumber(messageStatsCalculator.stats.bouncedCount);
    let openCount = formatStatNumber(messageStatsCalculator.stats.openCount);
    let uniqueOpenCount = formatStatNumber(messageStatsCalculator.stats.uniqueOpenCount);
    let clickCount = formatStatNumber(messageStatsCalculator.stats.clickCount);
    let uniqueClickCount = formatStatNumber(messageStatsCalculator.stats.uniqueClickCount);
    let unsubscribeCount = formatStatNumber(messageStatsCalculator.stats.unsubscribeCount);
    
    let sendingProgress = formatStatPercentage(messageStatsCalculator.sendingProgress);
    let pendingRate = formatStatPercentage(messageStatsCalculator.pendingRate);
    let failedRate = formatStatPercentage(messageStatsCalculator.failedRate);
    let bounceRate = formatStatPercentage(messageStatsCalculator.bounceRate);
    let openRate = formatStatPercentage(messageStatsCalculator.openRate);
    let uniqueOpenRate = formatStatPercentage(messageStatsCalculator.uniqueOpenRate);
    let clickRate = formatStatPercentage(messageStatsCalculator.clickRate);
    let uniqueClickRate = formatStatPercentage(messageStatsCalculator.uniqueClickRate);
    let unsubscribeRate = formatStatPercentage(messageStatsCalculator.unsubscribeRate);

    /** Render */
    return (
        <div className="mb-4">
            <Row>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_RECIPIENT_COUNT')}
                        value={recipientCount}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_PENDING_COUNT')}
                        value={pendingCount}
                        bottomValue={pendingRate}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_DELIVERED_COUNT')}
                        value={deliveredCount}
                        bottomValue={sendingProgress}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-success"
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_FAILED_COUNT')}
                        value={failedCount}
                        bottomValue={failedRate}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-danger"
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_OPEN_COUNT')}
                        value={openCount + ' (' + uniqueOpenCount + ')'}
                        bottomValue={openRate + ' (' + uniqueOpenRate + ')'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_CLICK_COUNT')}
                        value={clickCount + ' (' + uniqueClickCount + ')'}
                        bottomValue={clickRate + ' (' + uniqueClickRate + ')'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_UNSUBSCRIBE_COUNT')}
                        value={unsubscribeCount}
                        bottomValue={unsubscribeRate}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_BOUNCED_COUNT')}
                        value={bouncedCount}
                        bottomValue={bounceRate}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-warning"
                    />
                </Col>
            </Row>
        </div>
    );
}

export default EmailMessageStatsDashboard;