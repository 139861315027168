import { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Contact from '@business/contacts/Contact';
import ContactHistory from '@business/contacts/ContactHistory';
import Country from '@business/addresses/Country';
import CustomField, { CustomFieldValue } from '@business/fields/CustomField';
import CustomFieldsEditor from './CustomFieldsEditor';
import ContactGroup from '@business/contacts/ContactGroup';
import { CustomFieldAvailableFor } from '@business/fields/generated/CustomField.generated';
import { now, today } from '@xFrame4/common/Functions';
import { ContactGender } from '@business/contacts/generated/Contact.generated';

interface ContactFrontdeskFormProps extends EntityAddEditFormProps<Contact>
{
    
}

/**
 * Component: add/edit a contact and add contact history at the frontdesk. 
 */
const ContactFrontdeskForm: FunctionComponent<ContactFrontdeskFormProps> = (props) =>
{
    const t = useTranslate();
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Contact>(props.entity ?? new Contact());
    const [contactHistory, setContactHistory, updateContactHistoryField] = useUpdateEntityFields<ContactHistory>(new ContactHistory());
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [countries, setCountries] = useState<Country[]>([]);
    const [customFieldValues, setCustomFieldValues] = useState<CustomFieldValue[]>([]);
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
    const [contactHistoryCustomFieldValues, setContactHistoryCustomFieldValues] = useState<CustomFieldValue[]>([]);

    // Set the default values for Contact History
    useEffect(() => {
        let ch = new ContactHistory();
        ch.eventDate = now();
        ch.eventName = t('CHECK_IN');
        setContactHistory(ch);
    },[])
    
    /** Load additional data */
    useLoad(async () => {
        // load countries
        let countryResult = await Country.manager.load();
        if (countryResult != null) setCountries(countryResult.entities);

        // load custom fields and values
        let customFieldResult = await CustomField.manager.load({ availableFor_In: [CustomFieldAvailableFor.ALL, CustomFieldAvailableFor.CONTACT] });
        if (customFieldResult != null)
        {
            let cfvs: CustomFieldValue[] = await entity.customFieldValueManager.getValuesForCustomFields(customFieldResult.entities, mode == AddEditFormMode.Edit);
            setCustomFieldValues(cfvs);
        }
        
        // load existing contact groups
        let contactGroupResult = await entity?.contactGroups.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);

        // load contact history custom fields and values
        let contactHistoryCustomFieldResult = await CustomField.manager.load({ availableFor_In: [CustomFieldAvailableFor.ALL, CustomFieldAvailableFor.CONTACT_HISTORY] });
        if (contactHistoryCustomFieldResult != null)
        {
            let cfvs: CustomFieldValue[] = await contactHistory.customFieldValueManager.getValuesForCustomFields(contactHistoryCustomFieldResult.entities);
            setContactHistoryCustomFieldValues(cfvs);
        }
    });
    
    /**
     * Save the contact and the contact history.
     */
    const onSave = async () =>
    {
        // save contact
        let success = await entity.save();

        if (success)
        {
            // save custom field values
            for (let cfv of customFieldValues)
            {
                await entity.customFieldValueManager.setCustomFieldValue(cfv.customField, cfv.value);
            }

            // save contact history
            contactHistory.contact = entity;
            success = await contactHistory.save();

            // save contact history custom field values
            if (success)
            {
                for (let cfv of contactHistoryCustomFieldValues)
                {
                    await contactHistory.customFieldValueManager.setCustomFieldValue(cfv.customField, cfv.value);
                }
            }
        }
        
        return success;
    }

    /** Section: Contact - general */
    let cmpSectionContactGeneral = 
        <div className="mb-3">
            <div className="h4 mb-3 pb-1 border-bottom">{t('GENERAL')}</div>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Email}
                        field="email"
                        label={t('EMAIL')}
                        isRequired={true}
                        value={entity?.email}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="firstName"
                        label={t('FIRST_NAME')}
                        value={entity?.firstName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="lastName"
                        label={t('LAST_NAME')}
                        value={entity?.lastName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    /> 
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="phone"
                        label={t('PHONE')}
                        value={entity?.phone}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="gender"
                        label={t('GENDER')}
                        dataSource={enumToDataSource(ContactGender, t)}
                        value={entity?.gender}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isConfirmed"
                        label={t('IS_CONFIRMED')}
                        value={entity?.isConfirmed}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </div>
    ;

    /** Section: Contact - address */
    let sectionContactAddress =
        <div className="mb-3">
            <div className="h4 mb-3 pb-1 border-bottom">{t('ADDRESS')}</div>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="address"
                        label={t('ADDRESS')}
                        value={entity?.address}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="city"
                        label={t('CITY')}
                        value={entity?.city}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="country"
                        label={t('COUNTRY')}
                        dataSource={countries}
                        dataSourceValueMember="code"
                        dataSourceDisplayMember="name"
                        value={entity?.country}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </div>
    ;

    /** Section: Contact - custom fields */
    let cmpSectionContactCustomFields =
        <CustomFieldsEditor 
            customFieldValues={customFieldValues}
            onCustomFieldValuesChanged={(customFieldValues) => setCustomFieldValues(customFieldValues)}
        />
    
    /** Section: ContactHistory - general */
    let cmpSectionContactHistoryGeneral =
        <div className="mb-3">
            <div className="h4 mb-3 pb-1 border-bottom">{t('CHECK_IN')}</div>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="eventName"
                        label={t('EVENT_NAME')}
                        isRequired={true}
                        isEnabled={false}
                        value={contactHistory?.eventName}
                        onValueChanged={(field, value) => updateContactHistoryField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.DateTimeLocal}
                        field="eventDate"
                        label={t('EVENT_DATE')}
                        isRequired={true}
                        isEnabled={false}
                        value={contactHistory?.eventDate}
                        onValueChanged={(field, value) => updateContactHistoryField(field, value)}
                    />
                </Col>
            </Row>
        </div>
    ;

    /** Section: ContactHistory - custom fields */
    let cmpSectionContactHistoryCustomFields =
        <CustomFieldsEditor 
            customFieldValues={contactHistoryCustomFieldValues}
            onCustomFieldValuesChanged={(customFieldValues) => setContactHistoryCustomFieldValues(customFieldValues)}
        />

    /** Render */
    return (
        <>
            <EntityAddEditForm<Contact>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD_CONTACT_AT_FRONTDESK')}
                titleEdit={t('EDIT_CONTACT_AT_FRONTDESK') + ': ' + entity?.email}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                {cmpSectionContactGeneral}
                {sectionContactAddress}
                {cmpSectionContactCustomFields}
                {cmpSectionContactHistoryGeneral}
                {cmpSectionContactHistoryCustomFields}
            </EntityAddEditForm>
        </>
    );
}

export default ContactFrontdeskForm;