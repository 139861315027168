import client from '@xFrame4/business/GraphQlClient';

export class NextJsHelper
{
    /**
     * Revalidates the modified Next.js pages.
     * For this to work:
     * - The NEXTJS_APP_REVALIDATE_TOKEN env variable must exist for both the Next.js frontend and the API backend (probably Django)
     * - The Next.js api/revalidate/route.ts must check the key
     * - The target is the page or pages to revalidate. By default every page is revalidated (all). 
     * - You can also specify a list of paths to revalidate. E.g. ['blog', 'blog/[slug]', 'products', 'product_[id]']
     * - You can also specify custom targets like 'blog', 'blog/[slug]', 'products', 'product_[id]', etc.
     */
    static async revalidate(target: NextJsRevalidateTarget = { all: true })
    {
        const { gql } = await import('@apollo/client');

        // Get the secret URL via the API
        let queryText = `
        mutation NextJsRevalidate($target: NextJsRevalidateInput!) {
            nextJsRevalidate(target: $target) {
                success
            }
        }          
        `
        
        let { data } = await client.query({
            query: gql(queryText),
            variables: {
                target: target
            }
        });

        return data.nextJsRevalidate.success as boolean;
    }
}

export type NextJsRevalidateTarget = {
    all?: boolean;
    paths?: string[];
    customTargets?: string[];
}

export default NextJsHelper;