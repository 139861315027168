import BusinessEntity from '@xFrame4/business/base/BusinessEntity';
import AddEditForm, { AddEditFormMode, AddEditFormProps } from './AddEditForm';

export interface EntityAddEditFormProps<B extends BusinessEntity> extends AddEditFormProps
{
    /** The entity to edit. */
    entity?: B;
    /** Called when a field has been updated. */
    onUpdateEntityField?: (field: string, value: any) => void;
    /** Called after the form has been closed (the entity may not have been saved). Very similar to onClose, it has additional information about the mode and the entity. */
    afterEdit?: (mode: AddEditFormMode, entity: B) => void;
}

function EntityAddEditForm<B extends BusinessEntity>(props: EntityAddEditFormProps<B>)
{
    /** Default handler for saving the entity. */
    const onSave = async () =>
    {
        return props.entity!.save();
    };
    
    /** Render */
    return (
        <>
        {props.entity &&
            <AddEditForm 
                {...props}
                onSave={props.onSave ?? onSave}
                onClose={() => {
                    props.afterEdit?.(props.mode!, props.entity!);
                    props.onClose?.();
                }}
            >
                {props.children}
            </AddEditForm>
        }
        </>
    );
}

export default EntityAddEditForm;