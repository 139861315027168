import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import FieldEditor, { FieldEditorLabelPosition, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { BusinessEntityFilter } from '@xFrame4/business/base/BusinessEntity';

export type FilterEditor = {
    /** The field name is taken from the GraphQL schema (a query variable name). */
    field: string,
    label: string
    type: FieldEditorType,
    dataSource?: {[index: string]: any}[];
    dataSourceDisplayMember?: string;
    dataSourceValueMember?: string;
}

interface EntityViewFilterProps
{
    filterEditors: FilterEditor[];
    isLoading: boolean;
    /** If set: update the filter editors with the values from the predefined filter. */
    filter?: BusinessEntityFilter;
    onFilter: (businessEntityFilter: BusinessEntityFilter) => void;
}

const EntityViewFilter: FunctionComponent<EntityViewFilterProps> = (props) =>
{
    const [filterEditorValues, setFilterEditorValues] = useState<{[index: string]: any}>({});

    /** Hook: load the predefined filter values (if exists). */
    useEffect(() => {
        if (props.filter)
        {
            // set te filter editor values
            for (let field of Object.keys(props.filter))
            {
                // find the filter editor
                let filterEditor = props.filterEditors.find(fe => fe.field == field);
                if (!filterEditor) continue;
                
                // set the value
                if ([FieldEditorType.Select, FieldEditorType.List].includes(filterEditor.type))
                {
                    let dataSourceValueMember = filterEditor.dataSourceValueMember ?? 'value';
                    let filterEditorValue = props.filter[field];
                    let value: any = {};
                    value[dataSourceValueMember] = filterEditorValue;
                    onFilterEditorValueChanged(field, value);
                }
                else
                {
                    onFilterEditorValueChanged(field, props.filter[field]);
                }
            }
        }
    }, []);
    
    /** A filter editor value has changed: update the value */
    const onFilterEditorValueChanged = (field: string, value: any) =>
    {
        setFilterEditorValues((prev) => {
            let copy = Object.assign({}, prev);
            copy[field] = value;

            return copy;
        });
    };
    
    /** Convert the values from the filter editors to a BusinessEntityFilter */
    const getBusinessEntityFilter = () => 
    {
        let filter: BusinessEntityFilter = {};

        for (let filterEditor of props.filterEditors)
        {
            if (filterEditorValues[filterEditor.field] === undefined) continue; // skip undefined values

            let filterEditorValue = filterEditorValues[filterEditor.field];

            if ([FieldEditorType.Select, FieldEditorType.List].includes(filterEditor.type))
            {
                let dataSourceValueMember = filterEditor.dataSourceValueMember ?? 'value';
                
                if (filterEditor.type == FieldEditorType.Select)
                {
                    filterEditorValue = filterEditorValue[dataSourceValueMember];
                }
                else if (filterEditor.type == FieldEditorType.List)
                {
                    if (filterEditorValue.length == 0) continue; // skip empty list
                    filterEditorValue = filterEditorValue.map((v: any) => v[dataSourceValueMember]);
                }
            }

            filter[filterEditor.field] = filterEditorValue;
        }

        return filter;
    };

    /** Create filter editors */ 
    let cmpFilters = props.filterEditors.map(filterEditor =>
    {
        // render the filter editor
        return (
            <div key={filterEditor.field} className="d-flex align-items-center me-3">
                <FieldEditor
                    type={filterEditor.type}
                    field={filterEditor.field}
                    label={filterEditor.label}
                    labelPosition={FieldEditorLabelPosition.Vertical}
                    dataSource={filterEditor.dataSource}
                    dataSourceValueMember={filterEditor.dataSourceValueMember}
                    dataSourceDisplayMember={filterEditor.dataSourceDisplayMember}
                    value={filterEditorValues[filterEditor.field]}
                    onValueChanged={(field, value) => onFilterEditorValueChanged(field, value)}
                    onKeyDown={e => { if (e.key == 'Enter') props.onFilter(getBusinessEntityFilter()); }}
                />
            </div>
        )
    });

    /** Render */
    return (
        <div className="entity-view-filter d-flex">
            <div className="flex-grow-1 d-flex flex-wrap align-items-start">{cmpFilters}</div>
            <div className="d-flex align-items-start">
                <Button 
                    variant="primary" 
                    disabled={props.isLoading}
                    className="entity-view-filter-button"
                    onClick={() => props.onFilter(getBusinessEntityFilter())} 
                >
                    <i className="mdi mdi-filter"></i>
                </Button>
            </div>
        </div>
    );
}

export default EntityViewFilter;