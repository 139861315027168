import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import EmailTemplate from './EmailTemplate';
import EmailCampaignGenerated, { EmailCampaignGeneratedEntityManager } from './generated/EmailCampaign.generated';
import { BulkMessageStats } from './BulkMessageStatsManager';
import { t } from '@xFrame4/common/Utils';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class EmailCampaignEntityManager extends EmailCampaignGeneratedEntityManager<EmailCampaign>
{
    constructor()
    {
        super({
            createEntity: () => new EmailCampaign(),
            fields: [
                { name: 'recipientsCount', type: BusinessEntityFieldType.Integer, isInput: false },
                { name: 'sentCount', type: BusinessEntityFieldType.Integer, isInput: false },
                { name: 'pendingCount', type: BusinessEntityFieldType.Integer, isInput: false },
                { name: 'failedCount', type: BusinessEntityFieldType.Integer, isInput: false },
                { name: 'bouncedCount', type: BusinessEntityFieldType.Integer, isInput: false },
                { name: 'contactGroupNames', type: BusinessEntityFieldType.Custom, isInput: false },
                { name: 'segmentNames', type: BusinessEntityFieldType.Custom, isInput: false }
            ]
        });
    }
}

export class EmailCampaign extends EmailCampaignGenerated
{
    static _manager: EmailCampaignEntityManager;
    static get manager(): EmailCampaignEntityManager 
    {
        if (!this._manager) this._manager = new EmailCampaignEntityManager();
        return this._manager;
    }

    /**
     * The number of recipients for this campaign.
     * This is calculated on the server side by counting the contacts in the contact groups and segments.
     */
    recipientsCount: number = 0;

    /**
     * The number of emails that have been sent for this campaign.
     * This is calculated on the server side by counting the sent items in the email queue.
     */
    sentCount: number = 0;

    /**
     * The number of emails that are pending to be sent for this campaign.
     * This is calculated on the server side by counting the pending items in the email queue.
     */
    pendingCount: number = 0;

    /**
     * The number of emails that have failed to be sent for this campaign.
     * This is calculated on the server side by counting the failed items in the email queue.
     */
    failedCount: number = 0;

    /** 
     * The number of emails that have bounced for this campaign.
     * This is calculated on the server side by counting the bounced items in the email queue.
     */
    bouncedCount: number = 0;

    /**
     * The names of contact groups associated with this campaign.
     */
    contactGroupNames: string[] = [];

    /**
     * The names of segments associated with this campaign.
     */
    segmentNames: string[] = [];


    /**
     * Returns the sending progress as a percentage.
     */
    get sendingProgress(): number 
    {
        if (this.recipientsCount === 0) return 0;
        return Math.round((this.sentCount / this.recipientsCount) * 100);
    }

    /**
     * Schedule the email campaign to be sent at a specific date.
     * 
     * @param sendDate The date to send the email campaign. Format: Y:m:d H:i:s
     */
    async schedule(sendDate: string)
    {
        let query = `
            mutation ScheduleEmailCampaign($id: Int!, $sendDate: String!) {
                scheduleEmailCampaign(id: $id, sendDate: $sendDate) {
                    success
                    object {
                        ...${EmailCampaign.manager.name}DetailsFragment
                    }
                }
            }
            ${EmailCampaign.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                id: this.id,
                sendDate: sendDate
            }
        });

        if (result.data.scheduleEmailCampaign.success)
        {
            return EmailCampaign.manager.createFromGraphQL(result.data.scheduleEmailCampaign.object);
        }
        else
        {
            return null;
        }
    }

    /**
     * Schedule the email campaign to be sent immediately.
     */
    async scheduleForImmediateSending()
    {
        let query = `
            mutation ScheduleEmailCampaignForImmediateSending($id: Int!) {
                scheduleEmailCampaignForImmediateSending(id: $id) {
                    success
                    object {
                        ...${EmailCampaign.manager.name}DetailsFragment
                    }
                }   
            }
            ${EmailCampaign.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                id: this.id
            }
        });

        if (result.data.scheduleEmailCampaignForImmediateSending.success)
        {
            return EmailCampaign.manager.createFromGraphQL(result.data.scheduleEmailCampaignForImmediateSending.object);
        }
        else
        {
            return null;
        }
    }

    /**
     * Get the stats for the EmailCampaign.
     */
    async getStats()
    {
        let query = `
            query GetEmailCampaignStats($emailCampaignId: Int!) {
                getEmailCampaignStats(emailCampaignId: $emailCampaignId)
            }
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                emailCampaignId: this.id
            }
        });

        if (result.data.getEmailCampaignStats)
        {
            return JSON.parse(result.data.getEmailCampaignStats) as BulkMessageStats;
        }
        else
        {
            return null;
        }
    }

    /**
     * Create a draft email campaign.
     * 
     * @param emailTemplate The email template to use.
     * @param name The name of the campaign.
     * @param bodySoFar The body HTML of the campaign so far.
     */
    static async createDraft(emailTemplate: EmailTemplate, name: string, bodySoFar: string = '')
    {
        let query = `
            mutation CreateDraftEmailCampaign($emailTemplateId: Int!, $name: String!, $bodySoFar: String!) {
                createDraftEmailCampaign(emailTemplateId: $emailTemplateId, name: $name, bodySoFar: $bodySoFar) {
                    success
                    object {
                        ...${EmailCampaign.manager.name}DetailsFragment
                    }
                }
            }
            ${EmailCampaign.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                emailTemplateId: emailTemplate.id,
                name: name,
                bodySoFar: bodySoFar
            }
        });

        if (result.data.createDraftEmailCampaign.success)
        {
            return EmailCampaign.manager.createFromGraphQL(result.data.createDraftEmailCampaign.object);
        }
        else
        {
            return null;
        }
    }
}

export enum EmailCampaignScheduleType
{
    EMAIL_CAMPAIGN_SCHEDULE_TYPE_IMMEDIATELY = 'EMAIL_CAMPAIGN_SCHEDULE_TYPE_IMMEDIATELY',
    EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER = 'EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER'
}

export default EmailCampaign;
