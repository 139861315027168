import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';


export enum EmailTemplateBodyTemplateSource
{
    DB = 'DB',
    FILE = 'FILE',

}

export class EmailTemplateGeneratedEntityManager<B extends EmailTemplateGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'EmailTemplate',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'bodyTemplateSource', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'baseTemplateFile', type: BusinessEntityFieldType.VarChar },
				{ name: 'bodyTemplateFile', type: BusinessEntityFieldType.VarChar },
				{ name: 'bodyTemplateHtml', type: BusinessEntityFieldType.Text, isRequiredInput: true },
				{ name: 'language', type: BusinessEntityFieldType.VarChar },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'emailTemplates',
        });
    }

}
export class EmailTemplateGenerated extends BusinessEntity
{
	name!: string;
	/** Whether the body template is stored in the database or in a file. */
	bodyTemplateSource: EmailTemplateBodyTemplateSource = EmailTemplateBodyTemplateSource.DB;
	/** The name of the HTML which holds the base template */
	baseTemplateFile: string = 'email-hotel.html';
	/** The name of the HTML file which holds the body template. If empty, the body template is stored in the database. */
	bodyTemplateFile: string = '';
	/** The HTML of the body template. If empty, the body template is stored in a file. */
	bodyTemplateHtml: string = '';
	/** The language of the email template. */
	language: string = 'en';

}

export default EmailTemplateGenerated;