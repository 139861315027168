import { FunctionComponent, useEffect } from 'react';
import { LayoutColor, MenuItemType, SideBarTheme } from '@components/_admin/hyper/appConstants';
import { VerticalLayout } from '../hyper/layouts';
import { useAppActionDispatch } from '@redux/hooks';
import { setDynamicMenuItems } from '@redux/layoutSlice';
import Segment from '@business/contacts/Segment';
import ContactGroup from '@business/contacts/ContactGroup';

interface CustomAdminLayoutProps
{

}

const CustomAdminLayout: FunctionComponent<CustomAdminLayoutProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    
    /** Load the menu items based on database data */
    const loadDynamicMenuItems = async () =>
    {
        let menuItems: MenuItemType[] = [];

        // contact groups
        let contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult)
        {
            let contactGroups = contactGroupResult.entities;

            for (let contactGroup of contactGroups)
            {
                let menuItem: MenuItemType = {
                    key: `${contactGroup.id}`,
                    parentKey: 'my-contact-groups',
                    label: contactGroup.name,
                    url: `/my-contact-groups/${contactGroup.id}`,
                }
                menuItems.push(menuItem);
            }
        }

        // segments
        let segmentResult = await Segment.manager.load();
        if (segmentResult)
        {
            let segments = segmentResult.entities;

            for (let segment of segments)
            {
                let menuItem: MenuItemType = {
                    key: `${segment.id}`,
                    parentKey: 'my-segments',
                    label: segment.name,
                    url: `/my-segments/${segment.id}`,
                }
                menuItems.push(menuItem);
            }
        }

        dispatch(setDynamicMenuItems(menuItems));
    }

    useEffect(() => {
        loadDynamicMenuItems();
    }, []);
    
    /** Render */
    return (
        <>
            <VerticalLayout 
                leftSideBarTheme={SideBarTheme.LEFT_SIDEBAR_THEME_DEFAULT} 
            />
        </>
    );
}

export default CustomAdminLayout;