import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumns } from '@mui/x-data-grid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import Contact from '@business/contacts/Contact';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';
import EntityView from '@components/_admin/entity/EntityView';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import ContactAddEditForm from './ContactAddEditForm';
import ImportContactsForm from './ImportContactsForm';
import ImportContactHistoryForm from './ImportContactHistoryForm';
import ExportContactsForm from './ExportContactsForm';
import ContactFrontdeskForm from './ContactFrontdeskForm';

interface ContactViewProps
{

}

// Define the button interface
interface Button
{
    text: string;
    variant: string;
    onClick: () => void;
}

const ContactView: FunctionComponent<ContactViewProps> = (props) =>
{
    const t = useTranslate();
    const params = useParams();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<Contact>(Contact);
    const [contactFrontdeskEntity, setContactFrontdeskEntity] = useState<Contact>();
    const [showImportForm, setShowImportForm] = useState(false);
    const [showContactHistoryImportForm, setShowContactHistoryImportForm] = useState(false);
    const [showExportForm, setShowExportForm] = useState(false);
    const [baseFilter, setBaseFilter] = useState({});
    const [contactGroup, setContactGroup] = useState<ContactGroup | null>(null);
    const [segment, setSegment] = useState<Segment | null>(null);
    const [title, setTitle] = useState<string>(t('CONTACTS'));

    /** Load additional entities */
    useLoad(async () =>
    {
        if (params.contactGroupId)
        {// load contact group
            let t_contactGroup = await ContactGroup.manager.getById(parseInt(params.contactGroupId));
            setContactGroup(t_contactGroup);
            setBaseFilter({ contactGroup_Id: parseInt(params.contactGroupId) });
            setTitle(t('CONTACTS') + ' - ' + t_contactGroup!.name);
        }
        else if (params.segmentId)
        {// load segment
            let t_segment = await Segment.manager.getById(parseInt(params.segmentId));
            setSegment(t_segment);
            setBaseFilter({ segment_Id: parseInt(params.segmentId) });
            setTitle(t('CONTACTS') + ' - ' + t_segment!.name);
        }
        else
        {
            setContactGroup(null);
            setSegment(null);
            setBaseFilter({});
            setTitle(t('CONTACTS'));
        }
    }, [params]);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<Contact>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'dateAdded', headerName: t('DATE_ADDED'), type: 'date', flex: 1 },
            { field: 'email', headerName: t('EMAIL'), type: 'string', flex: 1 },
            { field: 'firstName', headerName: t('FIRST_NAME'), type: 'string', flex: 1 },
            { field: 'lastName', headerName: t('LAST_NAME'), type: 'string', flex: 1 },
            { field: 'isConfirmed', headerName: t('IS_CONFIRMED'), type: 'boolean' }
        ],
        [],
    );

    // buttons left
    let buttonsLeft = [];
    if (params.segmentId === undefined) // only show add buttons if we are not in a segment
    {
        buttonsLeft.push(
            {
                text: t('ADD'),
                variant: 'danger',
                onClick: () => onAddEditOpen(AddEditFormMode.Add)
            }
        );

        if (params.contactGroupId === undefined) // only show add at frontdesk button if we are not in a contact group list
        {
            buttonsLeft.push(
                {
                    text: t('ADD_CONTACT_AT_FRONTDESK'),
                    variant: 'danger',
                    onClick: () => setContactFrontdeskEntity(new Contact())
                }
            );
        }
    }

    // buttons right - add export button for contact groups
    let buttonsRight: Button[] = [];
    if (params.segmentId === undefined)
    {
        if (params.contactGroupId !== undefined)
        {
            // For contact group view, only show export button
            buttonsRight.push({
                text: t('EXPORT'),
                variant: 'success',
                onClick: () => setShowExportForm(true)
            });
        } else
        {
            // For main contacts view, show all buttons
            buttonsRight = [
                {
                    text: t('IMPORT'),
                    variant: 'success',
                    onClick: () => setShowImportForm(true)
                },
                {
                    text: t('IMPORT_CONTACT_HISTORY'),
                    variant: 'success',
                    onClick: () => setShowContactHistoryImportForm(true)
                },
                {
                    text: t('EXPORT'),
                    variant: 'success',
                    onClick: () => setShowExportForm(true)
                }
            ];
        }
    }

    /** Render */
    return (
        <>
            {addEditEntity &&
                <ContactAddEditForm
                    entity={addEditEntity}
                    defaultContactGroups={contactGroup ? [contactGroup] : undefined}
                    afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
                />
            }

            {contactFrontdeskEntity &&
                <ContactFrontdeskForm
                    entity={contactFrontdeskEntity}
                    afterEdit={(mode, entity) =>
                    {
                        setContactFrontdeskEntity(undefined);
                        onAddEditClose(mode, entity);
                    }}
                />
            }

            {showImportForm &&
                <ImportContactsForm
                    onClose={() => setShowImportForm(false)}
                    onImported={() => setBaseFilter({ ...baseFilter })} // changing the base filter will reload the grid
                />
            }

            {showContactHistoryImportForm &&
                <ImportContactHistoryForm
                    onClose={() => setShowContactHistoryImportForm(false)}
                />
            }

            {showExportForm &&
                <ExportContactsForm
                    onClose={() => setShowExportForm(false)}
                    contactGroup={contactGroup || undefined}
                />
            }

            <EntityView<Contact>
                id="contacts"
                title={title}
                manager={Contact.manager}
                baseFilter={baseFilter}
                pageSize={100}
                buttonsLeft={buttonsLeft}
                buttonsRight={buttonsRight}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'email_Icontains', label: t('EMAIL'), type: FieldEditorType.Text },
                    { field: 'firstName_Icontains', label: t('FIRST_NAME'), type: FieldEditorType.Text },
                    { field: 'lastName_Icontains', label: t('LAST_NAME'), type: FieldEditorType.Text },
                    { field: 'phone_Icontains', label: t('PHONE'), type: FieldEditorType.Text },
                ]}
                actions={[
                    { label: t('ADD_CONTACT_AT_FRONTDESK'), icon: <ReceiptLongIcon />, onClick: (entity) => setContactFrontdeskEntity(entity) }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.email + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default ContactView;