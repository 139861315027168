import { FunctionComponent, useState } from 'react';
import Permission from '@xFrame4/business/users/Permission';
import { useLoad } from '@xFrame4/components/Hooks';
import CheckboxList from '@xFrame4/components/common/CheckboxList';

interface PermissionsEditorProps
{
    selectedPermissions: Permission[];
    onChange: (permissions: Permission[]) => void;
}

/**
 * Component: Editor for permission selection
 */
const PermissionsEditor: FunctionComponent<PermissionsEditorProps> = (props) =>
{
    const [permissions, setPermissions] = useState<Permission[]>([]);

    useLoad(async () => {
        // load the permissions
        const permissionResult = await Permission.manager.load();
        if (permissionResult) setPermissions(permissionResult.entities);
    });
    
    /** Render */
    return (
        <>
            <CheckboxList 
                dataSource={permissions}
                dataSourceDisplayMember="name"
                dataSourceValueMember="id"
                controlId="permissions"
                value={props.selectedPermissions}
                onChange={(selectedItems) => props.onChange(selectedItems as Permission[])}
            />
        </>
    );
}

export default PermissionsEditor;