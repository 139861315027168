import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

export interface TripleStateCheckboxProps
{
    /** The current value of the checkbox */
    value?: boolean | undefined;
    /** The label to display next to the checkbox */
    label?: string;
    /** Whether the checkbox is disabled */
    disabled?: boolean;
    /** Additional CSS class names */
    className?: string;
    /** Callback when the value changes */
    onChange?: (value: boolean | undefined) => void;
}

/**
 * Component: a 3 state checkbox. The 3 states are:
 * - True
 * - False
 * - Undefined
 */
const TripleStateCheckbox: FunctionComponent<TripleStateCheckboxProps> = (props) =>
{
    const {
        value = undefined,
        label = '',
        disabled = false,
        onChange,
        className = ''
    } = props;

    const checkboxRef = useRef<HTMLInputElement>(null);

    // Update the indeterminate state when the value changes
    useEffect(() =>
    {
        if (checkboxRef.current)
        {
            checkboxRef.current.indeterminate = value === undefined;
            checkboxRef.current.checked = value === true;
        }
    }, [value]);

    // Handle the click event
    const handleClick = () =>
    {
        if (disabled) return;

        let newValue: boolean | undefined;

        // Cycle through the states: undefined -> true -> false -> undefined
        if (value === undefined)
        {
            newValue = true;
        } 
        else if (value === true)
        {
            newValue = false;
        } 
        else
        {
            newValue = undefined;
        }

        if (onChange)
        {
            onChange(newValue);
        }
    };

    /** Render */
    return (
        <Form.Check
            type="checkbox"
            ref={checkboxRef}
            label={label}
            disabled={disabled}
            className={className}
            checked={value === true}
            onChange={() => { }} // We handle changes in the onClick handler
            onClick={handleClick}
        />
    );
}

export default TripleStateCheckbox;