import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import AutomatedJourneyMessage from '@business/mailing/AutomatedJourneyMessage';
import EmailMessage from '@business/mailing/EmailMessage';
import { AutomatedJourneyMessageDelayUnit, AutomatedJourneyMessageSendAfterEvent } from '@business/mailing/generated/AutomatedJourneyMessage.generated';
import EmailMessageEditor from './EmailMessageEditor';
import AutomatedJourney from '@business/mailing/AutomatedJourney';

interface AutomatedJourneyMessageAddEditFormProps extends EntityAddEditFormProps<AutomatedJourneyMessage>
{
    automatedJourney: AutomatedJourney;
}

const AutomatedJourneyMessageAddEditForm: FunctionComponent<AutomatedJourneyMessageAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<AutomatedJourneyMessage>(props.entity ?? new AutomatedJourneyMessage());
    const [emailMessage, setEmailMessage] = useState<EmailMessage>(props.entity?.emailMessage ?? new EmailMessage());

    /** Save entity */
    const onSave = async () =>
    {
        let automatedJourneyMessage = entity;
        
        // on add: create the draft journey message
        if (mode === AddEditFormMode.Add)
        {
            let draftAutomatedJourneyMessage = await AutomatedJourneyMessage.createDraft(
                props.automatedJourney, 
                emailMessage.emailTemplate,
                entity.delay,
                entity.delayUnit as AutomatedJourneyMessageDelayUnit,
            );
            console.log('draft journey message created', draftAutomatedJourneyMessage);

            if (draftAutomatedJourneyMessage != null)
            {
                automatedJourneyMessage = draftAutomatedJourneyMessage;
            }
            else
            {
                return false;
            }
        }

        // update the email message
        console.log('emailMessage', emailMessage);
        automatedJourneyMessage.emailMessage.emailTemplate = emailMessage.emailTemplate;
        automatedJourneyMessage.emailMessage.subject = emailMessage.subject;
        automatedJourneyMessage.emailMessage.preheader = emailMessage.preheader;
        automatedJourneyMessage.emailMessage.body = emailMessage.body;
        console.log('automatedJourneyMessage.emailMessage', automatedJourneyMessage.emailMessage);
        await automatedJourneyMessage.emailMessage.save();
        console.log('email message saved');

        // save the journey message
        return await automatedJourneyMessage.save();
    }

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="orderNo"
                        label={t('ORDER_NO')}
                        isRequired={true}
                        value={entity?.orderNo}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="delay"
                        label={t('DELAY')}
                        isRequired={true}
                        value={entity?.delay}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="delayUnit"
                        label={t('DELAY_UNIT')}
                        dataSource={enumToDataSource(AutomatedJourneyMessageDelayUnit, t)}
                        isRequired={true}
                        value={entity?.delayUnit}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="sendAfterEvent"
                        label={t('SEND_AFTER_EVENT')}
                        dataSource={enumToDataSource(AutomatedJourneyMessageSendAfterEvent, t)}
                        value={entity?.sendAfterEvent}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: email message */
    let cmpTabEmailMessage =
    <>
        <EmailMessageEditor 
            emailMessage={emailMessage}
            onEmailMessageChanged={(emailMessage) => setEmailMessage(emailMessage)}
        />
    </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<AutomatedJourneyMessage>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('AUTOMATED_JOURNEY_MESSAGE').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.emailMessage?.subject}
                onClose={() => props.afterEdit?.(mode, entity)}
                onSave={() => onSave()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'emailMessage',
                            title: t('ADD_EDIT_TAB_EMAIL_MESSAGE'),
                            content: cmpTabEmailMessage,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default AutomatedJourneyMessageAddEditForm;