import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import OptInFormField from '@business/forms/OptInFormField';
import OptInFormFieldAddEditForm from './OptInFormFieldAddEditForm';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import OptInForm from '@business/forms/OptInForm';

interface OptInFormFieldViewProps
{
    optInForm: OptInForm
}

const OptInFormFieldView: FunctionComponent<OptInFormFieldViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<OptInFormField>(OptInFormField);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<OptInFormField>>(
        () => [
            { field: 'contactField', headerName: t('CONTACT_FIELD'), type: 'string', flex: 1 },
            { field: 'label', headerName: t('LABEL'), type: 'string', flex: 1 },
            { field: 'type', headerName: t('TYPE'), type: 'string', flex: 1, valueGetter: (params) => t(params.row.type) },
            { field: 'isRequired', headerName: t('IS_REQUIRED'), type: 'boolean', flex: 1 },
            { field: 'rowNum', headerName: t('ROW_NUM'), type: 'number', flex: 1 },
            { field: 'orderNoInRow', headerName: t('ORDER_NO_IN_ROW'), type: 'number', flex: 1 },
            { field: 'colSpan', headerName: t('COL_SPAN'), type: 'number', flex: 1 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <OptInFormFieldAddEditForm 
                entity={addEditEntity}
                optInForm={props.optInForm}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<OptInFormField>
                id="optInFormFields"
                isVisible={true}
                title={t('OPT_IN_FORM_FIELDS')}
                manager={OptInFormField.manager}
                baseFilter={{ optInForm_Id: props.optInForm.id ?? -1 }}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.contactField + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default OptInFormFieldView;