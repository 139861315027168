import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import Company from '@business/general/Company';
import Country from '@business/addresses/Country';
import { useNavigate } from 'react-router-dom';

interface CompanyAddEditFormProps extends EntityAddEditFormProps<Company>
{

}

const CompanyAddEditForm: FunctionComponent<CompanyAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const mode: AddEditFormMode = AddEditFormMode.Edit;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Company>(new Company()); // new keyword is used to create a dummy object
    const [countries, setCountries] = useState<Country[]>([]);

    /** Load additional data */
    useLoad(async () => {
        // load the company
        setEntity(await Company.theCompany());
        
        // load countries
        let countryResult = await Country.manager.load();
        if (countryResult != null) setCountries(countryResult.entities);
    });
    
    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="registrationNumber"
                        label={t('REGISTRATION_NUMBER')}
                        value={entity?.registrationNumber}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Email}
                        field="email"
                        label={t('EMAIL')}
                        value={entity?.email}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="website"
                        label={t('WEBSITE')}
                        value={entity?.website}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="phone"
                        label={t('PHONE')}
                        value={entity?.phone}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="logoImage"
                        label={t('LOGO')}
                        onValueChanged={(field, file) => updateEntityField(field, file)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="images"
                        label={t('IMAGES')}
                        allowMultipleFileUpload={true}
                        onValueChanged={(field, files) => updateEntityField(field, files)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: address */
    let cmpTabAddress =
        <>
            <Row>
                <Col lg={12}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="address"
                        label={t('ADDRESS')}
                        value={entity?.address}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="city"
                        label={t('CITY')}
                        value={entity?.city}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="region"
                        label={t('REGION')}
                        value={entity?.region}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="country"
                        label={t('COUNTRY')}
                        dataSource={countries}
                        dataSourceValueMember="code"
                        dataSourceDisplayMember="name"
                        value={entity?.country}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: mailing */
    /**
     *    - [from_name, from_email]
          - [reply_to_name, reply_to_email]
     */
    let cmpTabMailing =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="replyToName"
                        label={t('REPLY_TO_NAME')}
                        value={entity?.replyToName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Email}
                        field="replyToEmail"
                        label={t('REPLY_TO_EMAIL')}
                        value={entity?.replyToEmail}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<Company>
                entity={entity}
                mode={mode}
                isModal={false}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onCancel={() => document.location.reload()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'address',
                            title: t('ADD_EDIT_TAB_ADDRESS'),
                            content: cmpTabAddress,
                        },
                        {
                            eventKey: 'mailing',
                            title: t('ADD_EDIT_TAB_MAILING'),
                            content: cmpTabMailing,
                        },
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default CompanyAddEditForm;