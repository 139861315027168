import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import CustomField from '@business/fields/CustomField';
import CustomFieldAddEditForm from './CustomFieldAddEditForm';
import { CustomFieldType } from '@business/fields/generated/CustomField.generated';

interface CustomFieldViewProps
{

}

const CustomFieldView: FunctionComponent<CustomFieldViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<CustomField>(CustomField);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<CustomField>>(
        () => [
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'type', headerName: t('TYPE'), type: 'string', valueGetter: (params) => t(params.row.type) },
            { field: 'isRequired', headerName: t('IS_REQUIRED'), type: 'boolean' },
            { field: 'customFieldGroup', headerName: t('CUSTOM_FIELD_GROUP'), type: 'string', valueGetter: (params) => params.row.customFieldGroup?.name },
            { field: 'orderNo', headerName: t('ORDER_NO'), type: 'number' },
            { field: 'availableFor', headerName: t('AVAILABLE_FOR'), type: 'string', width: 150, valueGetter: (params) => t(params.row.availableFor) }
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <CustomFieldAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<CustomField>
                id="customFields"
                title={t('CUSTOM_FIELDS')}
                manager={CustomField.manager}
                baseFilter={{}}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                    {field: 'type_In', label: t('TYPE'), type: FieldEditorType.List, dataSource: enumToDataSource(CustomFieldType, t)}
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default CustomFieldView;