import client from '@xFrame4/business/GraphQlClient';
import TokenManager from '@xFrame4/business/TokenManager';
import User from './User';

export type UserAuthInfo = {
    /** The JWT in the local storage. It may not be valid. To get the valid token use getToken(). */
    token: string,
    /** The refresh token in the local storage. */
    refreshToken: string
}

export type SocialLoginResult = {
    /** True if the login was successful. */
    success: boolean,
    /** True if an existing user was logged in. */
    isLogin?: boolean,
    /** True if a new user was registered. */
    isRegister?: boolean
    /** The JWT for the user. */
    token?: string
    /** The refresh token for the user. */
    refreshToken?: string
}

/**
 * Represents the authenticable user. Uses a JWT to authenticate.
 * After authentication we can make requests to the API based on the assigned Django permissions.
 * 
 * Based on https://django-graphql-auth.readthedocs.io/en/latest/.
 */
export class AuthUser extends User
{
    /** Singleton. */
    private static _current: AuthUser | null = null;

    /** The JWTs in the local storage. */
    authInfo: UserAuthInfo = {
        token: '',
        refreshToken: ''
    }

    // Singleton
    private constructor()
    {
        super();
    }

    /**
     * Get the JWT of the user from the local storage. 
     * If the token is outdated it will try to refresh the token via the GraphQL API.
     */
    async getToken(): Promise<string | null>
    {
        let tokenManager = new TokenManager();

        return await tokenManager.getTokenFromLocalStorage();
    }

    /**
     * Singleton.
     * 
     * @returns The current app user.
     */
    static get current(): AuthUser | null
    {
        // If there is no local storage or there is no token in the local storage: set singleton to null
        if (typeof localStorage == 'undefined' || localStorage.getItem(TokenManager.localStorageTokenIdentifier) == null) this._current = null;

        return this._current;
    }

    /**
     * Register a user in the application. 
     * Logs in and assigns the registered user to the singleton object (if the registration was successful and email verification is not needed).
     * For Django core: https://django-graphql-auth.readthedocs.io/en/latest/quickstart/#register
     * 
     * @param email User email address.
     * @param password Password.
     * @returns The success and the token. If no token is returned, the user must verify the email address and cannot log in immediately. 
     */
    static async register(email: string, password: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation RegisterUser($email: String!, $password: String!) {
            register(email: $email, username: $email, password1: $password, password2: $password) {
                success
                token
                refreshToken
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                email: email,
                password: password
            }
        });

        if (data?.register?.success)
        {   
            // When the token is returned: this means that the user can log in immediately (without the email verification)
            // https://django-graphql-auth.readthedocs.io/en/latest/settings/#allow_login_not_verified
            if (data?.register?.token != null)
            {
                await this.login(email, password);
            }
        }

        return { success: data?.register?.success as boolean, token: data?.register?.token };
    }

    /**
     * Verify the registered user's email address after registration. The token is in the activation email.
     * For Django core: https://django-graphql-auth.readthedocs.io/en/latest/quickstart/#account-verification
     * 
     * @param token The account verification token received by email after registratiom.
     */
    static async verifyAccount(token: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation VerifyAccount($token: String!) {
            verifyAccount(token: $token) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                token: token
            }
        });

        return data?.verifyAccount?.success as boolean;
    }

    /**
     * Resend the account activation email to an email address. The email will contain a new activation link (token).
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#resendactivationemail
     * 
     * @param email The email of the registered user.
     */
    static async resendActivationEmail(email: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation ResendActivationEmail($email: String!) {
            resendActivationEmail(email: $email) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                email: email
            }
        });

        return data?.resendActivationEmail?.success as boolean;
    }

    /**
     * Send a password reset email to an email address.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#sendpasswordresetemail
     * 
     * @param email The user's email address.
     */
    static async sendPasswordResetEmail(email: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation SendPasswordResetEmail($email: String!) {
            sendPasswordResetEmail(email: $email) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
        mutation: gql(query),
            variables: {
                email: email
            }
        });

        return data?.sendPasswordResetEmail?.success as boolean;
    }

    /**
     * Change user password without old password, after receiving the password reset email.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#passwordreset
     * 
     * @param passwordResetToken The token received in the password reset email (sent with 'sendPasswordResetEmail').
     * @param newPassword1 The new password.
     * @param newPassword2 Confirm the new password.
     */
    static async passwordReset(passwordResetToken: string, newPassword1: string, newPassword2: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation PasswordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
            passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                token: passwordResetToken,
                newPassword1: newPassword1,
                newPassword2: newPassword2
            }
        });

        return data?.passwordReset?.success as boolean;
    }

    /**
     * Change user password with old password.
     * 
     * @param oldPassword The old password.
     * @param newPassword1 The new password.
     * @param newPassword2 Confirm the new password.
     */
    static async passwordChange(oldPassword: string, newPassword1: string, newPassword2: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation PasswordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
            passwordChange(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2) {
                success
                errors
                token
                refreshToken
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                oldPassword: oldPassword,
                newPassword1: newPassword1,
                newPassword2: newPassword2
            }
        });

        return data?.passwordChange?.success as boolean;
    }

    /**
     * Authenticate a user with email and password.
     * Assigns the logged in user to the singleton object.
     * 
     * @param email 
     * @param password 
     * @returns True if login was successful.
     */
    static async login(email: string, password: string)
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation LoginUser($email: String!, $password: String!) {
            tokenAuth(email: $email, password: $password) {
                success
                token
                refreshToken
                user {
                    ...UserDetailsFragment
                }
            }
        }

        ${User.UserDetailsGraphQlFragment}
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                email: email,
                password: password
            }
        });

        // Save token and refresh token to the local storage (may not be safe, but let's take some risks)
        if (data?.tokenAuth?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.tokenAuth?.token as string,
                refreshToken: data?.tokenAuth?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.tokenAuth.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return true;
        }
        else
        {
            //console.log(data?.tokenAuth?.errors);
            return false;
        }
    }

    /**
     * Login or register a user with data received from the Facebook Login Javascript SDK.
     * https://developers.facebook.com/docs/facebook-login/web/
     * 
     * @param accessToken The Facebook access token after FB login. Get it using the FacebookHelper class.
     */
    static async loginFacebookUser(accessToken: string): Promise<SocialLoginResult>
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation LoginFacebookUser($accessToken: String!) {
            loginFacebookUser(accessToken: $accessToken) {
                success
                isLogin
                isRegister
                token
                refreshToken
                user {
                    ...UserDetailsFragment
                }
            }
        }

        ${User.UserDetailsGraphQlFragment}
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                accessToken: accessToken,
            }
        });

        if (data?.loginFacebookUser?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.loginFacebookUser?.token as string,
                refreshToken: data?.loginFacebookUser?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.loginFacebookUser.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return { 
                success: data.loginFacebookUser.success,
                isLogin: data.loginFacebookUser.isLogin, 
                isRegister: data.loginFacebookUser.isRegister,
                token: data.loginFacebookUser.token,
                refreshToken: data.loginFacebookUser.refreshToken
            };
        }
        else
        {
            return { success: false };
        }
    }

    /**
     * Login or register a user with data received from the Sign in with Google Javascript SDK.
     * https://developers.google.com/identity/gsi/web/guides/overview
     * 
     * @param jwt The encoded JWT received from Google. Use the GoogleHelper class to get the JWT.
     */
    static async loginGoogleUser(jwt: string): Promise<SocialLoginResult>
    {
        const { gql } = await import('@apollo/client');

        let query = `
        mutation LoginGoogleUser($jwt: String!) {
            loginGoogleUser(jwt: $jwt) {
                success
                isLogin
                isRegister
                token
                refreshToken
                user {
                    ...UserDetailsFragment
                }
            }
        }

        ${User.UserDetailsGraphQlFragment}
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                jwt: jwt,
            }
        });

        if (data?.loginGoogleUser?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.loginGoogleUser?.token as string,
                refreshToken: data?.loginGoogleUser?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.loginGoogleUser.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return { 
                success: data.loginGoogleUser.success,
                isLogin: data.loginGoogleUser.isLogin, 
                isRegister: data.loginGoogleUser.isRegister,
                token: data.loginGoogleUser.token,
                refreshToken: data.loginGoogleUser.refreshToken
            };
        }
        else
        {
            return { success: false };
        }
    }

    /**
     * Get the current authenticated user based on the saved JWT.
     * The user has to be active and the JWT has to be valid.
     * 
     * @returns The current authenticated user or null.
     */
    static async loginFromJWT()
    {
        let tokenManager = new TokenManager();
        let token = await tokenManager.getTokenFromLocalStorage();
        if (token == null) return false;
        
        // Dynamically import the gql to speed up the initial loading
        const { gql } = await import('@apollo/client');
        
        let query = `
        query Me {
            me {
                ...UserDetailsFragment
            }
        }

        ${User.UserDetailsGraphQlFragment}
        `;

        let { data } = await client.query({
            query: gql(query),
            context: {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            }
        });

        if (data.me != null)
        {
            this._current = AuthUser.createFromGraphQL(data.me) as AuthUser;
            this._current.authInfo = {
                token: localStorage.getItem(TokenManager.localStorageTokenIdentifier) as string,
                refreshToken: localStorage.getItem(TokenManager.localStorageRefreshTokenIdentifier) as string,
            };

            return true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Log out the current authenticated user.
     */
    static async logout()
    {
        localStorage.removeItem(TokenManager.localStorageTokenIdentifier);
        localStorage.removeItem(TokenManager.localStorageRefreshTokenIdentifier);
        this._current = null;
    }

    /**
     * Verify the user JWT (is it still active?).
     */
    static async verifyUserToken()
    {
        let token = localStorage.getItem(TokenManager.localStorageTokenIdentifier);
        if (token == null) return false;
        
        // Dynamically import the gql to speed up the initial loading
        const { gql } = await import('@apollo/client');

        let query = `
        mutation VerifyUserToken($token: String!) {
            verifyToken(token: $token) {
                success
            }
        }
        `;

        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                token: token
            }
        });

        return data?.verifyToken?.success as boolean;
    }

    /**
     * Deactivate the current authenticated user's account.
     * After deactivation the user cannot log in anymore.
     */
    static async deactivateAccount()
    {
        let success = await AuthUser.current?.deActivate();

        if (success)
        {
            localStorage.removeItem(TokenManager.localStorageTokenIdentifier);
            localStorage.removeItem(TokenManager.localStorageRefreshTokenIdentifier);
            this._current = null;
        }

        return success;
    }
}