import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import ContactGroup from '@business/contacts/ContactGroup';

export enum OptInFormTheme
{
    DEFAULT = 'DEFAULT',

}

export class OptInFormGeneratedEntityManager<B extends OptInFormGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'OptInForm',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'description', type: BusinessEntityFieldType.Text },
				{ name: 'markAsConfirmed', type: BusinessEntityFieldType.Boolean },
				{ name: 'theme', type: BusinessEntityFieldType.VarChar },
				{ name: 'isPopup', type: BusinessEntityFieldType.Boolean },
				{ name: 'popupDelay', type: BusinessEntityFieldType.Integer },
				{ name: 'headerTitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'headerSubtitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'bodyText', type: BusinessEntityFieldType.Text },
				{ name: 'submitButtonCta', type: BusinessEntityFieldType.VarChar },
				{ name: 'submitButtonText', type: BusinessEntityFieldType.VarChar },
				{ name: 'declineText', type: BusinessEntityFieldType.VarChar },
				{ name: 'footerText', type: BusinessEntityFieldType.Text },
				{ name: 'thankYouTitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'thankYouText', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'optInForms',
        });
    }

}
export class OptInFormGenerated extends BusinessEntity
{
	name!: string;
	description: string = '';
	/** If true, contacts who submit the form will be marked as confirmed (opted in). */
	markAsConfirmed: boolean = false;
	/** The Bootstrap theme to use when rendering the form. */
	theme: OptInFormTheme = OptInFormTheme.DEFAULT;
	/** If true, the form will be rendered as a popup. */
	isPopup: boolean = false;
	/** The number of seconds to wait before showing the popup. */
	popupDelay: number = 0;
	/** The title of the header section of the form. */
	headerTitle: string = '';
	/** The subtitle of the header section of the form. */
	headerSubtitle: string = '';
	/** The text to display in the body section of the form. */
	bodyText: string = '';
	/** The call-to-action text to display above the submit button. */
	submitButtonCta: string = '';
	/** The text to display on the submit button. */
	submitButtonText: string = '';
	/** The text to display on decline text/button. */
	declineText: string = '';
	/** The text to display in the footer section of the form. */
	footerText: string = '';
	/** The title of the thank you section of the form. */
	thankYouTitle: string = '';
	/** The text to display in the thank you section of the form. */
	thankYouText: string = '';

	contactGroups: ManyToManyCollection<ContactGroup> = new ManyToManyCollection<ContactGroup>(this, 'business/forms/OptInForm', 'contact_groups', 'business/contacts/ContactGroup', ContactGroup.manager, 'optInForm');
}

export default OptInFormGenerated;