import React, { useState, useEffect, useRef } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import EmailCampaign from '@business/mailing/EmailCampaign';
import { EmailCampaignStatus } from '@business/mailing/generated/EmailCampaign.generated';
import EmailCampaignWizard from './EmailCampaignWizard';
import BulkMessageStatsForm from './BulkMessageStatsForm';
import SplitTestMessagesForm from './SplitTestMessagesForm';
import { LinearProgress, Tooltip, Box, Chip, Stack } from '@mui/material';
import StatusBadge from '@components/_admin/hyper/components/StatusBadge';
import GroupIcon from '@mui/icons-material/Group';
import SegmentIcon from '@mui/icons-material/Segment';

interface EmailCampaignViewProps
{

}

const EmailCampaignView: FunctionComponent<EmailCampaignViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<EmailCampaign>(EmailCampaign);
    const [bulkMessageStatsEntity, setBulkMessageStatsEntity] = useState<EmailCampaign>();
    const [entityWithSplitTestMessages, setEntityWithSplitTestMessages] = useState<EmailCampaign>();
    const [campaigns, setCampaigns] = useState<EmailCampaign[]>([]);
    
    /** Load the initial campaigns to get status */
    useEffect(() => {
        const loadInitialCampaigns = async () => {
            const result = await EmailCampaign.manager.load();
            if (result) {
                setCampaigns(result.entities);
            }
        };
        
        loadInitialCampaigns();
    }, []);

    /** Status color mapping for the badges */
    const statusColorMapping = {
        [EmailCampaignStatus.DRAFT]: 'info',
        [EmailCampaignStatus.SCHEDULED]: 'warning',
        [EmailCampaignStatus.SENDING]: 'primary',
        [EmailCampaignStatus.SENT]: 'success',
        [EmailCampaignStatus.CANCELLED]: 'danger'
    };

    /** Render status badge */
    const renderStatusBadge = (params: any) => {
        return (
            <StatusBadge 
                text={t(params.value as string)}
                status={params.value}
                colorMapping={statusColorMapping}
                defaultColor="secondary"
            />
        );
    };
    
    /** Progress bar renderer */
    const renderSendingProgress = (params: any) => {
        const campaign = params.row as EmailCampaign;
        const progress = campaign.sendingProgress;
        const statusText = `${campaign.sentCount} / ${campaign.recipientsCount}`;
        
        return (
            <Tooltip title={`${progress}% - ${statusText}`}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{statusText}</div>
                        <div>{progress}%</div>
                    </Box>
                    <LinearProgress variant="determinate" value={progress} sx={{ height: 8, borderRadius: 2 }} />
                </Box>
            </Tooltip>
        );
    };

    /** Recipients renderer - displays contact groups and segments */
    const renderRecipients = (params: any) => {
        const campaign = params.row;
        const hasContactGroups = campaign.contactGroupNames && campaign.contactGroupNames.length > 0;
        const hasSegments = campaign.segmentNames && campaign.segmentNames.length > 0;
        
        if (!hasContactGroups && !hasSegments) {
            return <span className="text-muted">{t('NO_RECIPIENTS_SELECTED')}</span>;
        }
        
        return (
            <Stack direction="column" spacing={1}>
                {hasContactGroups && (
                    <Box>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            {campaign.contactGroupNames.map((name: string, index: number) => (
                                <Chip 
                                    key={`cg-${index}`}
                                    size="small"
                                    icon={<GroupIcon />}
                                    label={name}
                                    sx={{ mb: 0.5, mr: 0.5 }}
                                />
                            ))}
                        </Stack>
                    </Box>
                )}
                
                {hasSegments && (
                    <Box>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            {campaign.segmentNames.map((name: string, index: number) => (
                                <Chip 
                                    key={`seg-${index}`}
                                    size="small"
                                    icon={<SegmentIcon />}
                                    label={name}
                                    sx={{ mb: 0.5, mr: 0.5 }}
                                    color="primary"
                                    variant="outlined"
                                />
                            ))}
                        </Stack>
                    </Box>
                )}
            </Stack>
        );
    };

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<EmailCampaign>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { 
                field: 'status', 
                headerName: t('STATUS'), 
                type: 'string', 
                width: 150,
                renderCell: renderStatusBadge
            },
            {
                field: 'recipients',
                headerName: t('RECIPIENTS'),
                width: 250,
                sortable: false,
                renderCell: renderRecipients
            },
            { field: 'sendDate', headerName: t('SEND_DATE'), type: 'dateTime', width: 200 },
            { 
                field: 'sendingProgress', 
                headerName: t('SENDING_PROGRESS'), 
                renderCell: renderSendingProgress,
                width: 200,
                sortable: false
            },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
                <EmailCampaignWizard
                    emailCampaign={addEditEntity}
                    afterFinish={(mode, entity) => onAddEditClose(mode, entity)}
                />
            }

            {bulkMessageStatsEntity &&
                <BulkMessageStatsForm
                    title={t('BULK_MESSAGE_STATS') + ' - ' + bulkMessageStatsEntity.name}
                    emailCampaign={bulkMessageStatsEntity}
                    onClose={() => setBulkMessageStatsEntity(undefined)}
                />
            }

            {entityWithSplitTestMessages &&
                <SplitTestMessagesForm
                    emailCampain={entityWithSplitTestMessages}
                    onClose={() => setEntityWithSplitTestMessages(undefined)}
                />
            }

            <EntityView<EmailCampaign>
                id="emailCampaigns"
                isVisible={addEditEntity === undefined && bulkMessageStatsEntity === undefined}
                title={t('EMAIL_CAMPAIGNS')}
                manager={EmailCampaign.manager}
                baseFilter={{}}
                pageSize={30}
                buttonsLeft={[
                    {
                        text: t('CREATE_EMAIL_CAMPAIGN'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text },
                    { field: 'status_In', label: t('STATUS_IN'), type: FieldEditorType.List, dataSource: enumToDataSource(EmailCampaignStatus, t) },
                    { field: 'sendDate_Gte', label: t('SEND_DATE_GTE'), type: FieldEditorType.Date },
                    { field: 'sendDate_Lte', label: t('SEND_DATE_LTE'), type: FieldEditorType.Date }
                ]}
                actions={[
                    { label: t('STATS'), icon: <QueryStatsIcon />, onClick: (entity) => setBulkMessageStatsEntity(entity) },
                    { label: t('SPLIT_TEST_MESSAGES'), icon: <CallSplitIcon />, onClick: (entity) => setEntityWithSplitTestMessages(entity), isHidden: (entity) => entity.isSplitTest == false }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default EmailCampaignView;