import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import PageTitle from '@components/_admin/hyper/components/PageTitle';
import { FunctionComponent, useState } from 'react';
import BulkMessageStatsManager, { BulkMessagePeriodChunkStats, BulkMessageStats, MessageStatsCalculator } from '@business/mailing/BulkMessageStatsManager';
import { addToDate, formatDate, numberFormat, today } from '@xFrame4/common/Functions';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import SimpleStatWidget from './SimpleStatWidget';
import FieldEditor, { FieldEditorLabelPosition, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import LineChart from './LineChart';

interface StatsViewProps
{

}

const StatsView: FunctionComponent<StatsViewProps> = (props) =>
{
    const t = useTranslate();
    const [dateStart, setDateStart] = useState<string>(formatDate(addToDate(today(), { days: -30 })));
    const [dateEnd, setDateEnd] = useState<string>(today());
    const [messageStatsCalculator, setMessageStatCalculator] = useState<MessageStatsCalculator>();
    const [detailedStats, setDetailedStats] = useState<BulkMessagePeriodChunkStats[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /** Load stats at first render */
    useLoad(async () =>
    {
        await loadStats();
    });

    /**
     * Load the stats for the given period.
     */
    const loadStats = async () =>
    {
        setIsLoading(true);

        // load stats
        const statsForPeriod = await BulkMessageStatsManager.getBulkMessageStatsForPeriod(undefined, dateStart, dateEnd);
        const detailedStatsForPeriod = await BulkMessageStatsManager.getBulkMessageDetailedStatsForPeriod(undefined, dateStart, dateEnd);
        console.log(statsForPeriod);
        console.log(detailedStatsForPeriod);

        if (statsForPeriod)
        {
            // create the calculator
            const calculator = new MessageStatsCalculator(statsForPeriod);
            setMessageStatCalculator(calculator);
        }

        if (detailedStatsForPeriod) setDetailedStats(detailedStatsForPeriod);

        setIsLoading(false);
    }

    /** Render */
    return (
        <>
            <div className="stats-view pt-3">
                <Row>
                    <Col lg={4}>
                        <PageTitle title={t('STATISTICS')} />
                    </Col>
                    <Col lg={8}>
                        <div className="d-flex justify-content-end mb-3">
                            <FieldEditor
                                type={FieldEditorType.Date}
                                field="dateStart"
                                label={t('BULK_MESSAGE_STAT_PERIOD_DATE_START')}
                                isEnabled={!isLoading}
                                value={dateStart}
                                onValueChanged={(field, value) => setDateStart(value)}
                                className="me-2"
                            />
                            <FieldEditor
                                type={FieldEditorType.Date}
                                field="dateEnd"
                                label={t('BULK_MESSAGE_STAT_PERIOD_DATE_END')}
                                isEnabled={!isLoading}
                                value={dateEnd}
                                onValueChanged={(field, value) => setDateEnd(value)}
                                className="me-2"
                            />
                            <Button 
                                className="align-self-center"
                                onClick={() => loadStats()}
                            >
                                <i className="mdi mdi-autorenew"></i>
                            </Button>
                        </div>
                    </Col>
                </Row>

                {isLoading && 
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }

                {!isLoading && messageStatsCalculator &&
                    <div className="stats">
                        <div className="chart-stats mb-4">
                            <Row>
                                <Col lg={6}>
                                    <LineChart 
                                        title={t('BULK_MESSAGE_STAT_DELIVERED_COUNT')}
                                        label={t('BULK_MESSAGE_STAT_DELIVERED_COUNT')}
                                        labels={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.chunkName)}
                                        data={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.stats.deliveredCount)}
                                        color='35b8e0'
                                    />
                                </Col>
                                <Col lg={6}>
                                    <LineChart
                                        title={t('BULK_MESSAGE_STAT_OPEN_COUNT')}
                                        label={t('BULK_MESSAGE_STAT_OPEN_COUNT')}
                                        labels={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.chunkName)}
                                        data={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.stats.openCount)}
                                        color='10c469'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <LineChart
                                        title={t('BULK_MESSAGE_STAT_CLICK_COUNT')}
                                        label={t('BULK_MESSAGE_STAT_CLICK_COUNT')}
                                        labels={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.chunkName)}
                                        data={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.stats.clickCount)}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <LineChart
                                        title={t('BULK_MESSAGE_STAT_UNSUBSCRIBE_COUNT')}
                                        label={t('BULK_MESSAGE_STAT_UNSUBSCRIBE_COUNT')}
                                        labels={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.chunkName)}
                                        data={detailedStats.map((chunk: BulkMessagePeriodChunkStats) => chunk.stats.unsubscribeCount)}
                                        color='ff5b5b'
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="widgets-stats mb-4">
                            <Row>
                                <Col sm={6} xxl={3}>
                                    <SimpleStatWidget
                                        title={t('BULK_MESSAGE_STAT_DELIVERED_COUNT')}
                                        value={numberFormat(messageStatsCalculator.stats.deliveredCount, 0, '.', ',')}
                                        bottomValue={numberFormat(messageStatsCalculator.sendingProgress, 2, '.', ',') + '%'}
                                        mainTextClass="text-white"
                                        bottomValueClass='text-white'
                                        backgroundClass="bg-info"
                                    />
                                </Col>
                                <Col sm={6} xxl={3}>
                                    <SimpleStatWidget
                                        title={t('BULK_MESSAGE_STAT_OPEN_COUNT')}
                                        value={numberFormat(messageStatsCalculator.stats.openCount, 0, '.', ',')}
                                        bottomValue={numberFormat(messageStatsCalculator.openRate, 2, '.', ',') + '%'}
                                        mainTextClass="text-white"
                                        bottomValueClass='text-white'
                                        backgroundClass="bg-success"
                                    />
                                </Col>
                                <Col sm={6} xxl={3}>
                                    <SimpleStatWidget
                                        title={t('BULK_MESSAGE_STAT_CLICK_COUNT')}
                                        value={numberFormat(messageStatsCalculator.stats.clickCount, 0, '.', ',')}
                                        bottomValue={numberFormat(messageStatsCalculator.clickRate, 2, '.', ',') + '%'}
                                    />
                                </Col>
                                <Col sm={6} xxl={3}>
                                    <SimpleStatWidget
                                        title={t('BULK_MESSAGE_STAT_UNSUBSCRIBE_COUNT')}
                                        value={numberFormat(messageStatsCalculator.stats.unsubscribeCount, 0, '.', ',')}
                                        bottomValue={numberFormat(messageStatsCalculator.unsubscribeRate, 2, '.', ',') + '%'}
                                        mainTextClass="text-white"
                                        bottomValueClass='text-white'
                                        backgroundClass="bg-danger"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default StatsView;