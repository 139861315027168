import { gql } from '@apollo/client'
import client from '@graphql/apollo-client'

export enum BulkMessageType
{
    EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN',
    AUTOMATED_JOURNEY_MESSAGE = 'AUTOMATED_JOURNEY_MESSAGE',
}

/** The statistics for a bulk message (EmailCampaign/AutomatedJourneyMessage). It can contain one or more EmailMessageStats. */
export type BulkMessageStats = EmailMessageStat[];

/** The statistics for a single EmailMessage */
export type EmailMessageStat = {
    emailMessageId: number,
    stats: MessageStats
}

/** The statistics for a single messages or a group of messages (EmailCampaign/AutomatedJourneyMessage). */
export type MessageStats = {
    recipientCount: number,
    pendingCount: number,
    deliveredCount: number,
    failedCount: number,
    bouncedCount: number,
    openCount: number,
    uniqueOpenCount: number,
    clickCount: number,
    uniqueClickCount: number,
    unsubscribeCount: number,
    contactEmailOpenStats?: ContactEmailOpenStat[],
    contactEmailClickStats?: ContactEmailClickStat[],
    urlClickCountStats?: UrlClickCountStat[],
    urlClickContactStats?: UrlClickContactStat[],
    contactUnsubscribeStats?: ContactUnsubscribeStat[]
}

/** Stats for a period chunk (day/week/month) */
export type BulkMessagePeriodChunkStats = {
    chunkName: string,
    stats: MessageStats
}

/** How many times an email was opened by a contact */
export type ContactEmailOpenStat = {
    contactId: number,
    contactEmail: string,
    emailOpenCount: number,
}

/** How many times a URL was clicked */
export type UrlClickCountStat = {
    url: string,
    clickCount: number
}

/** How many times a URL was clicked by a contact */
export type UrlClickContactStat = {
    url: string,
    contactStats: {
        contactId: number,
        contactEmail: string,
        emailClickCount: number
    }[]
}

/** How many times a contact clicked a link in an email */
export type ContactEmailClickStat = {
    contactId: number,
    contactEmail: string,
    emailClickCount: number
}

/** How many times a contact unsubscribed from a list (or more lists) in an email */
export type ContactUnsubscribeStat = {
    contactId: number,
    contactEmail: string,
    unsubscribeCount: number
}

/**
 * Calculates additional stats based on the MessageStats.
 */
export class MessageStatsCalculator
{
    stats: MessageStats;
    
    constructor(messageStats: MessageStats)
    {
        this.stats = messageStats;
    }

    /**
     * Returns the progress of the sending process as a percentage.
     */
    get sendingProgress(): number
    {
        let sendingProgress = this.stats.recipientCount != 0 ? this.stats.deliveredCount / this.stats.recipientCount : 0;
        
        return sendingProgress * 100;
    }

    /**
     * Returns the percentage of pending emails (not yet sent).
     */
    get pendingRate(): number
    {
        let pendingRate = this.stats.recipientCount != 0 ? this.stats.pendingCount / this.stats.recipientCount : 0;
        
        return pendingRate * 100;
    }

    /**
     * Returns the percentage of failed emails.
     */
    get failedRate(): number
    {
        let failedRate = this.stats.recipientCount != 0 ? this.stats.failedCount / this.stats.recipientCount : 0;
        
        return failedRate * 100;
    }

    /**
     * Returns the percentage of bounced emails.
     */
    get bounceRate(): number
    {
        let bounceRate = this.stats.recipientCount != 0 ? this.stats.bouncedCount / this.stats.recipientCount : 0;
        
        return bounceRate * 100;
    }

    /**
     * Returns the percentage of opened emails.
     */
    get openRate(): number
    {
        let openRate = this.stats.recipientCount != 0 ? this.stats.openCount / this.stats.recipientCount : 0;
        
        return openRate * 100;
    }
    
    /**
     * Returns the percentage of unique opens.
     */
    get uniqueOpenRate(): number
    {
        let uniqueOpenRate = this.stats.recipientCount != 0 ? this.stats.uniqueOpenCount / this.stats.recipientCount : 0;
        
        return uniqueOpenRate * 100;
    }

    /**
     * Get the percentage of clicks relative to the number of recipients.
     */
    get clickRate(): number
    {
        let clickRate = this.stats.recipientCount != 0 ? this.stats.clickCount / this.stats.recipientCount : 0;
        
        return clickRate * 100;
    }

    /**
     * Get the percentage of unique clicks.
     */
    get uniqueClickRate(): number
    {
        let uniqueClickRate = this.stats.recipientCount != 0 ? this.stats.uniqueClickCount / this.stats.recipientCount : 0;
        
        return uniqueClickRate * 100;
    }

    /**
     * Get the percentage of clicks relative to the number of opens.
     */
    get clickRatePerOpen(): number
    {
        let clickRatePerOpen = this.stats.openCount != 0 ? this.stats.clickCount / this.stats.openCount : 0;
        
        return clickRatePerOpen * 100;
    }

    /**
     * Get the percentage of unique clicks relative to the number of unique opens.
     */
    get uniqueClickRatePerOpen(): number
    {
        let uniqueClickRatePerOpen = this.stats.uniqueOpenCount != 0 ? this.stats.uniqueClickCount / this.stats.uniqueOpenCount : 0;
        
        return uniqueClickRatePerOpen * 100;
    }

    /**
     * Get the percentage of unsubscribes.
     */
    get unsubscribeRate(): number
    {
        let unsubscribeRate = this.stats.recipientCount != 0 ? this.stats.unsubscribeCount / this.stats.recipientCount : 0;
        
        return unsubscribeRate * 100;
    }
}

export class BulkMessageStatsManager
{
    /**
     * Get the stats about BulkMessages (EmailCampaigns/AutomatedJourneyMessages) for a period of time.
     * 
     * @param bulkMessageType The type of BulkMessage to get stats for.
     * @param dateStart The start date of the period.
     * @param dateEnd The end date of the period.
     */
    static async getBulkMessageStatsForPeriod(bulkMessageType?: BulkMessageType, dateStart?: string, dateEnd?: string)
    {
        let query = `
            query GetBulkMessageStatsForPeriod($bulkMessageType: String, $dateStart: String, $dateEnd: String) {
                getBulkMessageStatsForPeriod(bulkMessageType: $bulkMessageType, dateStart: $dateStart, dateEnd: $dateEnd)
            }
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                bulkMessageType: bulkMessageType,
                dateStart: dateStart,
                dateEnd: dateEnd
            }
        });

        if (result.data.getBulkMessageStatsForPeriod)
        {
            return JSON.parse(result.data.getBulkMessageStatsForPeriod) as MessageStats;
        }
        else
        {
            return null;
        }
    }

    /**
     * Get the detailed (breakdown by days/weeks/months) stats about BulkMessages (EmailCampaigns/AutomatedJourneyMessages) for a period of time.
     * 
     * @param bulkMessageType The type of BulkMessage to get stats for.
     * @param dateStart The start date of the period.
     * @param dateEnd The end date of the period.
     */
    static async getBulkMessageDetailedStatsForPeriod(bulkMessageType?: BulkMessageType, dateStart?: string, dateEnd?: string)
    {
        let query = `
            query GetBulkMessageDetailedStatsForPeriod($bulkMessageType: String, $dateStart: String, $dateEnd: String) {
                getBulkMessageDetailedStatsForPeriod(bulkMessageType: $bulkMessageType, dateStart: $dateStart, dateEnd: $dateEnd)
            }
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                bulkMessageType: bulkMessageType,
                dateStart: dateStart,
                dateEnd: dateEnd
            }
        });

        if (result.data.getBulkMessageDetailedStatsForPeriod)
        {
            let bulkMessagePeriodChunkStatsList: BulkMessagePeriodChunkStats[] = [];

            let stats = JSON.parse(result.data.getBulkMessageDetailedStatsForPeriod);
            for (let chunkName in stats)
            {
                let bulkMessagePeriodChunkStats: BulkMessagePeriodChunkStats = {
                    chunkName: chunkName,
                    stats: stats[chunkName]
                };
                bulkMessagePeriodChunkStatsList.push(bulkMessagePeriodChunkStats);
            }

            return bulkMessagePeriodChunkStatsList;
        }
        else
        {
            return null;
        }
    }
}

export default BulkMessageStatsManager;