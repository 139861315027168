import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class CountryGeneratedEntityManager<B extends CountryGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Country',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'code', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'countries',
        });
    }

}
export class CountryGenerated extends BusinessEntity
{
	/** The ISO 3166-1 alpha-2 code of the country. */
	code!: string;
	/** The name of the country. */
	name!: string;

}

export default CountryGenerated;