import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import ContactGroup from '@business/contacts/ContactGroup';
import FenyoHelper from '@business/fenyo/FenyoHelper';
import AddEditForm, { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';

interface FenyoImportFormProps
{

}

const FenyoImportForm: FunctionComponent<FenyoImportFormProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
    const [contactGroup, setContactGroup] = useState<ContactGroup>();

    useLoad(async () => {
        // load contact groups
        let contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);
    });

    /**
     * Close the form.
     */
    const onClose = () => 
    {
        navigate('/contacts');
    }

    /**
     * Import contacts from Excel file.
     */
    const onImport = async () : Promise<boolean> =>
    {
        setIsProcessing(true);

        let success = await FenyoHelper.importFenyoContactsAndHistories(file!, contactGroup!);

        setIsProcessing(false);

        return success;
    }

    /** Tab: General */
    let cmpTabGeneral =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="file"
                        label={t('FILE')}
                        isRequired={true}
                        onValueChanged={(field, file) => {
                            setFile(file);
                        }}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="contactGroup"
                        label={t('CONTACT_GROUP')}
                        isRequired={true}
                        dataSource={contactGroups}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        value={contactGroup}
                        onValueChanged={(field, contactGroup) => setContactGroup(contactGroup)}
                    />
                </Col>
            </Row>
            { isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </>
    
    /** Render */
    return (
        <>
            <AddEditForm 
                mode={AddEditFormMode.Add}
                title={t('FENYO_IMPORT_CONTACTS')}
                saveButtonLabel={t('IMPORT')}
                errorMessage={errorMessage}
                onSave={() => onImport()}
                onClose={() => onClose()}
            >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
            </AddEditForm>
        </>
    );
}

export default FenyoImportForm;