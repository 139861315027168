import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import FieldEditor, { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import AutomatedJourney from '@business/mailing/AutomatedJourney';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';
import { AutomatedJourneyStatus } from '@business/mailing/generated/AutomatedJourney.generated';
import AutomatedJourneyMessageView from './AutomatedJourneyMessageView';

interface AutomatedJourneyAddEditFormProps extends EntityAddEditFormProps<AutomatedJourney>
{

}

const AutomatedJourneyAddEditForm: FunctionComponent<AutomatedJourneyAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<AutomatedJourney>(props.entity ?? new AutomatedJourney());
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
    const [selectedContactGroups, setSelectedContactGroups] = useState<ContactGroup[]>([]);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);

    /** Load additional data */
    useLoad(async () => {
        // load contact groups
        let contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult != null) setContactGroups(contactGroupResult.entities);

        // load selected contact groups
        if (mode === AddEditFormMode.Edit)
        {
            let selectedContactGroupResult = await entity.contactGroups.load();
            if (selectedContactGroupResult != null) setSelectedContactGroups(selectedContactGroupResult.entities);
        }

        // load segments
        let segmentResult = await Segment.manager.load();
        if (segmentResult != null) setSegments(segmentResult.entities);

        // load selected segments
        if (mode === AddEditFormMode.Edit)
        {
            let selectedSegmentResult = await entity.segments.load();
            if (selectedSegmentResult != null) setSelectedSegments(selectedSegmentResult.entities);
        }
    });

    /** Save */
    const onSave = async () =>
    {
        // save entity
        let result = await entity.save();
        
        if (result)
        {
            // set contact groups and segments
            await entity.contactGroups.set(selectedContactGroups);
            await entity.segments.set(selectedSegments);
        }
        
        return result;
    }

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="status"
                        label={t('STATUS')}
                        isRequired={true}
                        dataSource={enumToDataSource(AutomatedJourneyStatus, t)}
                        value={entity?.status}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: recipients */
    let cmpTabRecipients =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.List}
                        field="contactGroups"
                        label={t('CONTACT_GROUPS')}
                        dataSource={contactGroups}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        value={selectedContactGroups}
                        onValueChanged={(field, value) => setSelectedContactGroups(value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.List}
                        field="segments"
                        label={t('SEGMENTS')}
                        dataSource={segments}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        value={selectedSegments}
                        onValueChanged={(field, value) => setSelectedSegments(value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: messages */
    let cmpTabMessages =
        <>
            <AutomatedJourneyMessageView
                automatedJourney={entity}
            />
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<AutomatedJourney>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('AUTOMATED_JOURNEY').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
                onSave={() => onSave()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'recipients',
                            title: t('ADD_EDIT_TAB_RECIPIENTS'),
                            content: cmpTabRecipients,
                        },
                        {
                            eventKey: 'messages',
                            title: t('ADD_EDIT_TAB_MESSAGES'),
                            content: cmpTabMessages,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default AutomatedJourneyAddEditForm;