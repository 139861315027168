import config from '@config';
import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useAuthUserLoginFromJWT, useTranslate } from '@xFrame4/components/Hooks';
import { useAppStateSelector } from '@redux/hooks';

interface AuthContainerProps
{
    children: JSX.Element[];
    bottomLinks: JSX.Element;
}

const AuthContainer: FunctionComponent<AuthContainerProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);

    /** Init */
    useEffect(() => {
        if (isUserLoggedIn) navigate('/');
    }, [isUserLoggedIn]);
    
    /** CSS */
    useEffect(() => {
        document.body.classList.add('authentication-bg');

        return () => document.body.classList.remove('authentication-bg');
    }, []);

    /** Render */
    return (
        <>
            <div className="">
                <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5} xxl={4}>
                                <Card>
                                    {/* logo */}
                                    <Card.Header className="pt-4 pb-4 text-center bg-primary">
                                        <Link to="#">
                                            <span>
                                                <img src={config.publicDirBaseUrl + 'img/logo/logo-wide.webp'} alt="" height="20" />
                                            </span>
                                        </Link>
                                    </Card.Header>
                                    <Card.Body className="p-4">{props.children}</Card.Body>
                                </Card>

                                {props.bottomLinks}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="footer footer-alt">
                    <div className="pb-env">
                    {'© 2024 ' + t('APP_TITLE')}
                    </div>
                </footer>
            </div>
        </>
    );
}

export default AuthContainer;