import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import AutomatedJourney from '@business/mailing/AutomatedJourney';
import { AutomatedJourneyStatus } from '@business/mailing/generated/AutomatedJourney.generated';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';
import AutomatedJourneyAddEditForm from './AutomatedJourneyAddEditForm';

interface AutomatedJourneyViewProps
{
    
}

const AutomatedJourneyView: FunctionComponent<AutomatedJourneyViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<AutomatedJourney>(AutomatedJourney);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<AutomatedJourney>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'status', headerName: t('STATUS'), type: 'string', valueGetter: (params) => t(params.row.status) },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <AutomatedJourneyAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<AutomatedJourney>
                id="automatedJourneys"
                title={t('AUTOMATED_JOURNEYS')}
                manager={AutomatedJourney.manager}
                baseFilter={{}}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                    {field: 'status_In', label: t('STATUS_IN'), type: FieldEditorType.List, dataSource: enumToDataSource(AutomatedJourneyStatus, t)}
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default AutomatedJourneyView;