import React from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import SmtpSetup from '@business/mailing/SmtpSetup';
import SmtpSetupAddEditForm from './SmtpSetupAddEditForm';

interface SmtpSetupViewProps
{

}

const SmtpSetupView: FunctionComponent<SmtpSetupViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<SmtpSetup>(SmtpSetup);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<SmtpSetup>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'host', headerName: t('HOST'), type: 'string', flex: 1 },
            { field: 'port', headerName: t('PORT'), type: 'number', width: 100 },
            { field: 'username', headerName: t('USERNAME'), type: 'string', flex: 1 },
            { field: 'encryption', headerName: t('ENCRYPTION'), type: 'string', width: 140 },
            { field: 'isDefault', headerName: t('IS_DEFAULT'), type: 'boolean', width: 120 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <SmtpSetupAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<SmtpSetup>
                id="smtpSetups"
                title={t('SMTP_SETUPS')}
                manager={SmtpSetup.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default SmtpSetupView;