import { FunctionComponent } from 'react';
import EmailMessage from '@business/mailing/EmailMessage';
import { Container } from 'react-bootstrap';
import StatsDetailsRow, { StatDetailsRowData } from './StatDetailsRow';
import TabContainer from '@components/_admin/entity/TabContainer';

interface StatDetailsContainerProps
{
    statDetails: {
        emailMessage: EmailMessage;
        statDetailsRows: StatDetailsRowData[];
    }[];
}

/**
 * Component: rows listing stats with details (eg: email opens for contact, url click count etc.)
 * If there are multiple email messages, a tab will be created for each message.
 * If there is only one email message, the tab will be hidden, only the rows will be displayed.
 */
const StatDetailsContainer: FunctionComponent<StatDetailsContainerProps> = (props) =>
{
    console.log('StatDetailsContainer', props);
    
    /** The content for a single email message */
    let cmpSingleMessage = 
        <Container>
            {
                props.statDetails[0].statDetailsRows.map((statDetailsRow, index) => 
                    <StatsDetailsRow
                        key={index}
                        statDetailsRowData={statDetailsRow}
                    />
                )
            }
        </Container>

    /** Tabs for multiple email messages */
    let cmpTabs = props.statDetails.map((statDetail, index) => {
        let cmpTabContent = 
            <Container key={index}>
                {
                    statDetail.statDetailsRows.map((statDetailsRow, rowIndex) => 
                        <StatsDetailsRow
                            key={rowIndex}
                            statDetailsRowData={statDetailsRow}
                        />
                    )
                }
            </Container>

        return {
            eventKey: statDetail.emailMessage.id as string,
            title: statDetail.emailMessage.id + ' - ' + statDetail.emailMessage.subject,
            content: <div>{cmpTabContent}</div>
        };
    });
    
    /** Render */
    return (
        <>
            {props.statDetails.length == 1 && cmpSingleMessage}

            {props.statDetails.length > 1 &&
                <TabContainer 
                    tabs={cmpTabs}
                />
            }
        </>
    );
}

export default StatDetailsContainer;