import React, { FunctionComponent, Suspense, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
//import { changeSidebarType } from 'redux/actions';
import { useToggle, useViewport } from './../hooks';
import { changeBodyAttribute } from './../utils';
import * as layoutConstants from './../appConstants';
import { LayoutColor, LayoutWidth, SideBarTheme, SideBarWidth } from './../appConstants';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { changeSidebarType } from '@redux/layoutSlice';
import { Outlet } from 'react-router-dom';

interface VerticalLayoutProps
{
    layoutColor?: LayoutColor;
    layoutWidth?: LayoutWidth;
    leftSideBarTheme?: SideBarTheme;
}

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

const VerticalLayout: FunctionComponent<VerticalLayoutProps> = (props) => {
    const dispatch = useAppActionDispatch();
    const { width } = useViewport();
    const [isMenuOpened, toggleMenu] = useToggle();


    const layoutColor: string= LayoutColor.LAYOUT_COLOR_LIGHT;
    const layoutWidth: string = LayoutWidth.LAYOUT_WIDTH_FLUID;
    const leftSideBarTheme: string = props.leftSideBarTheme ?? SideBarTheme.LEFT_SIDEBAR_THEME_DARK;
    const leftSideBarType: string = useAppStateSelector((state) => state.layout.leftSideBarType);

    /*
     * layout defaults
     */
    useEffect(() => {
        changeBodyAttribute('data-layout', layoutConstants.LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);

    useEffect(() => {
        changeBodyAttribute('data-layout-mode', layoutWidth);
    }, [layoutWidth]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-theme', leftSideBarTheme);
    }, [leftSideBarTheme]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType);
    }, [leftSideBarType]);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        toggleMenu();

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (width > 768 && width <= 1028) {
            dispatch(changeSidebarType(layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (width > 1028) {
            dispatch(changeSidebarType(layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED));
        }
    }, [dispatch, width]);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const isCondensed = leftSideBarType === layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED;
    const isLight = leftSideBarTheme === layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT;

    return (
        <>
            <div className="wrapper">
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} />
                </Suspense>
                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                        </Suspense>
                        <Container fluid>
                            <Outlet />
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>
        </>
    );
};

export default VerticalLayout;