import BusinessEntity, { EntityManager } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '../base/Constants';
import ManyToManyCollection from '@xFrame4/business/base/ManyToManyCollection';
import Group from './Group';
import User from './User';

export class Permission extends BusinessEntity
{
    codename!: string
    name!: string;
    users: ManyToManyCollection<User> = new ManyToManyCollection<User>(this, 'xFrame/business/users/Permission', 'users', 'xFrame/business/users/User', User.manager);
    groups: ManyToManyCollection<Group> = new ManyToManyCollection<Group>(this, 'xFrame/business/users/Permission', 'groups', 'xFrame/business/users/Group', Group.manager);

    static manager: EntityManager<Permission> = new EntityManager<Permission>({
        name: 'Permission',
        createEntity: () => new Permission(),
        fields: [
            { name: 'codename', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
            { name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
        ],
        graphQlQueryAlias: 'permissions'
    });
}

export default Permission;