import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@components/_admin/entity/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@components/_admin/entity/EntityViewHooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import { BusinessEntityFilter } from '@xFrame4/business/base/BusinessEntity';
import ContactHistory from '@business/contacts/ContactHistory';
import Contact from '@business/contacts/Contact';
import ContactHistoryAddEditForm from './ContactHistoryAddEditForm';

interface ContactHistoryViewProps
{
    contact: Contact;
}

const ContactHistoryView: FunctionComponent<ContactHistoryViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<ContactHistory>(ContactHistory);
    const [baseFilter , setBaseFilter] = useState<BusinessEntityFilter>({ contact_Id: props.contact.id ?? -1 });

    /** Set the base filter to the contact. */
    useEffect(() => {
        setBaseFilter({ contact_Id: props.contact.id ?? 0 });
    }, [props.contact]);    

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<ContactHistory>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'contact.email', headerName: t('EMAIL'), type: 'string', valueGetter: (params) => params.row.contact.email, flex: 1 },
            { field: 'eventDate', headerName: t('EVENT_DATE'), type: 'dateTime', flex: 1 },
            { field: 'eventName', headerName: t('EVENT_NAME'), type: 'string', flex: 1 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <ContactHistoryAddEditForm 
                entity={addEditEntity}
                contact={props.contact}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<ContactHistory>
                id="contactHistories"
                title={t('CONTACT_HISTORY')}
                manager={ContactHistory.manager}
                baseFilter={baseFilter}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'contact_Email_Icontains', label: t('EMAIL'), type: FieldEditorType.Text},
                    {field: 'eventName_Icontains', label: t('EVENT_NAME'), type: FieldEditorType.Text}
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ` ${entity.contact.email} ` + t('CONTACT_HISTORY') + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default ContactHistoryView;