'use client';
import { FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getDataSourceDisplayMemberValue } from './FieldEditor';
import { useTranslate } from '../Hooks';

export enum CheckboxListLayout
{
    Vertical,
    Horizontal
}

interface CheckboxListProps
{
    value: { [index: string]: any }[];
    dataSource: { [index: string]: any }[];
    dataSourceDisplayMember?: string;
    dataSourceValueMember?: string;
    layout?: CheckboxListLayout
    /** Show a search box to quickly filter the items. */
    showSearchBox?: boolean;
    /** Show the selected items as small badges. */
    showSelectedItems?: boolean;
    /** Is the control disabled? */
    disabled?: boolean;
    /** Unique identifier for the checkbox list. Which will be used as the base for the ids of the checkboxes. */
    controlId?: string;
    className?: string;
    onChange?: (selectedItems: { [index: string]: any }[]) => void;
}

const CheckboxList: FunctionComponent<CheckboxListProps> = (props) =>
{
    // Default props
    const dataSourceValueMember = props.dataSourceValueMember ?? 'value';
    const dataSourceDisplayMember = props.dataSourceDisplayMember ?? 'display';
    const layout = props.layout ?? CheckboxListLayout.Vertical;
    const showSearchBox = props.showSearchBox ?? false;
    const showSelectedItems = props.showSelectedItems ?? false;

    const t = useTranslate();
    const [checkedItems, setCheckedItems] = useState<{ [index: string]: any }[]>(props.value as []);
    if (props.value !== undefined && props.value != checkedItems) setCheckedItems(props.value); // hack: forcing 'checked' to be the value
    const [dataSource, setDataSource] = useState<{ [index: string]: any }[]>(props.dataSource);
    const [searchText, setSearchText] = useState<string>('');

    /** Filter the datasource based on the search text. */
    useEffect(() =>
    {
        if (searchText.length == 0)
        {
            setDataSource(props.dataSource);
        }
        else
        {
            let filteredDataSource = props.dataSource.filter(item => item[dataSourceDisplayMember].toLowerCase().includes(searchText.toLowerCase()));
            setDataSource(filteredDataSource);
        }
    }, [searchText, props.dataSource]);

    /** Handle the checkbox toggle for an item. */
    const handleToggle = (item: { [index: string]: any }) =>
    {
        if (props.disabled) return;

        // Update checked items
        const currentIndex = indexOfItemInList(checkedItems, item);
        const newCheckedItems = [...checkedItems];

        if (currentIndex === -1)
        {
            newCheckedItems.push(item);
        } else
        {
            newCheckedItems.splice(currentIndex, 1);
        }

        setCheckedItems(newCheckedItems);

        // Fire the change event
        if (props.onChange) props.onChange(newCheckedItems);
    };

    /** Find the index of an item in the list. */
    const indexOfItemInList = (list: { [index: string]: any }[], item: { [index: string]: any }) => 
    {
        return list.findIndex(i => i[dataSourceValueMember!] == item[dataSourceValueMember!]);
    };

    /** Searchbox component */
    let cmpSearchBox = showSearchBox ?
        <Form.Control
            type="text"
            placeholder={t('SEARCH')}
            className="mb-1"
            style={{ padding: '0.25rem 0.5rem', fontSize: '0.875rem' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
        />
        : null;

    /** Selected items component */
    let cmpSelectedItems = checkedItems.map((item, i) =>
        <div 
            key={i} 
            className="badge bg-primary text-white mb-1 me-1 font-size- cursor-pointer" 
            onClick={() => handleToggle(item)}
        >
            <span className="badge-close me-1">
                <i className="mdi mdi-close"></i>
            </span>
            {getDataSourceDisplayMemberValue(item, dataSourceDisplayMember as string)}
        </div>
    );

    /** Checkbox components */
    let cmpCheckboxes = dataSource.map((item, i) =>
        <Form.Check
            key={i}
            id={props.controlId ? `${props.controlId}-${i}` : undefined}
            type="checkbox"
            label={getDataSourceDisplayMemberValue(item, dataSourceDisplayMember as string)}
            checked={checkedItems && checkedItems.some(ci => ci[dataSourceValueMember as string] == item[dataSourceValueMember as string])}
            className="me-2"
            onChange={() => handleToggle(item)}
        />
    );

    /** Layout */
    let classes = '';
    if (layout == CheckboxListLayout.Vertical)
    {
        classes = '';
    }
    else
    {
        classes = 'd-flex'
    }

    /** Render */
    return (
        <div
            className={'checkbox-list border p-1 ' + classes + ' ' + (props.className ?? '')}
        >
            {showSearchBox && cmpSearchBox}

            {showSelectedItems && cmpSelectedItems.length > 0 &&
                <div className="d-flex flex-wrap mt-2 mb-1">
                    {cmpSelectedItems}
                </div>
            }

            <div style={{ maxHeight: '10rem', overflow: 'auto' }}>
                {cmpCheckboxes}
            </div>
        </div>
    );
}

export default CheckboxList;