import { FunctionComponent } from 'react';

export interface PageTitleProps
{
    title: string;
}

/**
 * Component: a page title component for Hyper theme.
 */
const PageTitle: FunctionComponent<PageTitleProps> = (props) =>
{
    /** Render */
    return (
        <div className="page-title-box">
            <h4 className="page-title">{props.title}</h4>
        </div>
    );
}

export default PageTitle;