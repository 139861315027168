'use client';
import { useState, useEffect, DependencyList, Dispatch, SetStateAction } from 'react';
import { useAppStateSelector, useAppActionDispatch } from '@redux/hooks';
import { setIsUserLoggedIn } from '@xFrame4/redux/userSlice';
import { formatPrice, tl } from '@xFrame4/common/Utils';
import { formatDateTimeUserFriendly, formatDateUserFriendy, formatTime, formatTimeUserFriendly, numberFormat } from '@xFrame4/common/Functions';
import { BootstrapBreakpoint } from '@xFrame4/common/Constants';
import BusinessEntity, { } from '@xFrame4/business/base/BusinessEntity';
import { AuthUser } from '@xFrame4/business/users/AuthUser';

/**
 * Hook: copies the value of a component prop object to a component state object when the prop changes.
 * Warning: this hook can sometimes cause an infinite loop (Maximum update depth exceeded). In that case, use the useEffect hook instead.
 * 
 * @param setter The setter function of the useState hook.
 * @prop prop An object from the component props.
 */
export function useSetStateFromProp<T>(setter: Dispatch<SetStateAction<T>>, prop: T)
{
    useEffect(() =>
    {
        setter(prop);
    }, [prop]);
}

/**
 * Hook: load data asynchronously.
 * 
 * @param loader An async function that loads the data.
 */
export function useLoad(loader: () => void, deps: DependencyList = [])
{
    useEffect(() =>
    {
        loader();
    }, deps);
}

/**
 * Hook: translate a text to the selected app language from the Redux store.
 */
export function useTranslate()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);

    const t = (text: string) => 
    {
        return tl(text, appLanguage.code);
    };

    return t;
}

/**
 * Hook: format a date to a user-friendly format.
 */
export function useFormatDateUserFriendy()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    const f = (dateString: string, options?: Intl.DateTimeFormatOptions) =>
    {
        return formatDateUserFriendy(dateString, appLanguage.locale, options);
    }
    
    return f;
}

/**
 * Hook: format a time to a user-friendly format.
 */
export function useFormatTimeUserFriendy()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    const f = (timeString: string, options?: Intl.DateTimeFormatOptions) =>
    {
        return formatTimeUserFriendly(timeString, appLanguage.locale, options);
    }
    
    return f;
}

/**
 * Hook: format a date-time string to a user-friendly format.
 */
export function useFormatDateTimeUserFriendly()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);

    const f = (dateTimeString: string, options?: Intl.DateTimeFormatOptions) =>
    {
        return formatDateTimeUserFriendly(dateTimeString, appLanguage.locale, options);
    }

    return f;
}

/** 
 * Hook: formats a price. For now the currency is hardcoded in the translations.json file.
 */
export function useFormatPrice()
{
    const f = (price: number, showCurrency = true) =>
    {
        return formatPrice(price, showCurrency);
    }

    return f;
}

/**
 * Hook: try to log in the user from the JWT in the local storage.
 * If success: sets the isUserLoggedIn in the Redux store to true.
 * 
 * @param deps 
 * @returns 
 */
export function useAuthUserLoginFromJWT(deps?: React.DependencyList)
{
    const dispatch = useAppActionDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);
    const [hasAuthRequestFinished, setHasAuthRequestFinished] = useState<boolean>(false);

    async function loginFromJWT()
    {
        setIsLoading(true);

        if (!isUserLoggedIn)
        {
            let success = await AuthUser.loginFromJWT();
            setHasAuthRequestFinished(true);
            if (success && AuthUser.current != null) 
            {
                dispatch(setIsUserLoggedIn(success && AuthUser.current != null));
            }
        }

        setIsLoading(false);
    }

    useEffect(() =>
    {
        loginFromJWT();
    }, deps);

    return { 
        isLoading, 
        isUserLoggedIn, 
        hasAuthRequestFinished 
    };
}

/**
 * Hook: update the fields of an entity in an AddEditForm.
 * 
 * @param p_Entity The entity.
 * @returns The entity, the entity setter and the field updater function.
 */
export function useUpdateEntityFields<B extends BusinessEntity>(p_Entity: B): [B, React.Dispatch<React.SetStateAction<B>>, (field: string, value: any) => void]
{
    // Set the entity
    const [entity, setEntity] = useState<B>(p_Entity);

    // Update the field
    const updateEntityField = (field: string, value: any) =>
    {
        setEntity(prevEntity =>
        {
            let copy = prevEntity.copy();
            // @ts-ignore
            (copy as BusinessEntity)[field] = value;

            return copy as B;
        });
    };

    return [entity, setEntity, updateEntityField];
}

/**
* Hook: loads and executes external JS scripts. It's doing by adding the script to the body element.
* 
* @param scriptUrls The scripts that need to be executed.
* @param isEnabled Load the scripts or not? Needed, beacause hooks cannot be inside conditions.
* @param deps 
*/
export function useLoadExternalScripts(scriptUrls: string[], isEnabled: boolean = true, deps?: React.DependencyList)
{
    let scripts: HTMLScriptElement[] = [];
    const [loadedScriptsCount, setLoadedScriptsCount] = useState<number>(0);
    const [areLoaded, setAreLoaded] = useState<boolean>(false);

    /** Called when a script is loaded. */
    const onScriptLoad = () =>
    {
        // check if all scripts are loaded
        if (loadedScriptsCount + 1 == scriptUrls.length) setAreLoaded(true);

        // update the loaded scripts count
        setLoadedScriptsCount(prevCount => prevCount + 1);
    }

    useEffect(() =>
    {
        if (isEnabled)
        {
            for (let scriptUrl of scriptUrls)
            {
                // check if the script is already in the document
                if (document.querySelector(`script[src="${scriptUrl}"]`))
                {
                    // if it's already loaded, consider it as loaded
                    onScriptLoad();
                    continue;
                }

                // load the script
                let script = document.createElement("script");
                script.src = scriptUrl;
                script.async = true;
                script.onload = onScriptLoad;
                document.body.appendChild(script);
                scripts.push(script);
            }

            return () =>
            {
                for (let script of scripts)
                {
                    if (document.body.contains(script)) document.body.removeChild(script);
                }
            }
        }
    }, deps);

    return [areLoaded, loadedScriptsCount];
}

/**
 * Hook: set a timeout and clear it when the component unmounts.
 * 
 * @param callback The callback function.
 * @param timeout The timeout in milliseconds.
 * @param deps
 */
export function useSetTimeout(callback: () => void, timeout: number, deps?: React.DependencyList)
{
    useEffect(() =>
    {
        let timeoutId = setTimeout(callback, timeout);
        return () => clearTimeout(timeoutId);
    }, deps);
}

/**
 * Hook: set an interval and clear it when the component unmounts.
 * 
 * @param callback The callback function.
 * @param interval The interval in milliseconds.
 */
export function useSetInterval(callback: () => void, interval: number, deps?: React.DependencyList)
{
    useEffect(() =>
    {
        let intervalId = setInterval(callback, interval);
        return () => clearInterval(intervalId);
    }, deps);
}

/**
 * Hook: hide a HTML element when clicked outside of it.
 * 
 * @param selector The selector of the element.
 * @param onClose The function to call when the element is clicked outside.
 * @param excludeClasses The classes that should not trigger the onClose function.
 */
export function useHideWhenClickedOutside(selector: string, onClose: () => void, excludeClasses: string[] = [])
{
    useEffect(() =>
    {
        const handleClickOutside = (event: MouseEvent) =>
        {
            if (event.target instanceof Element)
            {
                if (event.target.closest(selector) == null && !excludeClasses.some(c => (event.target as Element).classList.contains(c)))
                {
                    onClose();
                }
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () =>
        {
            document.removeEventListener('click', handleClickOutside);
        }
    }
        , []);
}

/**
 * Hook: disable body scroll when a condition is met.
 * 
 * @param isEnabled Enable or disable the body scroll.
 * @param breakpoint The max breakpoint when the body scroll should be disabled.
 */
export function useDisableBodyScroll(isEnabled: boolean, breakpoint?: BootstrapBreakpoint)
{
    useEffect(() =>
    {
        if (isEnabled)
        {
            if (breakpoint == undefined || window.innerWidth < breakpoint)
            {
                document.body.classList.add('no-scroll');
            }
        }
        else
        {
            document.body.classList.remove('no-scroll');
        }

        return () =>
        {
            document.body.classList.remove('no-scroll');
        };
    }, [isEnabled]);
}

/**
 * Hook: scroll to the top of the page every time the deps change.
 */
export function useScrollToTop(deps?: React.DependencyList)
{
    useEffect(() =>
    {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, deps);
}

/**
 * Hook: jump to the top of the page every time the deps change.
 */
export function useJumpToTop(deps?: React.DependencyList)
{
    useEffect(() =>
    {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, deps);
}